import {
  IonList,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonThumbnail,
} from "@ionic/react";

const SkeletonLoader: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { rows } = props;

  const el = Array.from({ length: rows }, (_, index) => index);
  return (
    <IonList>
      {el.map((item, index) => (
        <IonItem key={index}>
          <IonThumbnail slot="start">
            <IonSkeletonText animated={true}></IonSkeletonText>
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText
                animated={true}
                style={{ width: "80%" }}
              ></IonSkeletonText>
            </h3>
            <p>
              <IonSkeletonText
                animated={true}
                style={{ width: "60%" }}
              ></IonSkeletonText>
            </p>
            <p>
              <IonSkeletonText
                animated={true}
                style={{ width: "30%" }}
              ></IonSkeletonText>
            </p>
          </IonLabel>
        </IonItem>
      ))}
    </IonList>
  );
};

interface ILayoutProps {
  rows: number;
}

export default SkeletonLoader;
