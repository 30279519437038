import { useState, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonChip,
} from "@ionic/react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { IconPlus } from "@tabler/icons-react";

import { Contact, Settings } from "../../../interfaces";

import "./Chats.css";

//Components
import ChatList from "../../../components/ChatList";
import SkeletonLoader from "../../../components/SkeletonLoader";

//API
import ContactsApi from "../../../api/contacts";

const list: Contact[] = [];

const NewConversation = lazy(
  () => import("../../../components/new-conversation")
);

const Chats: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { contacts, loading, escalatedCount, settings } = props;

  const [filters, setFilters] = useState<(keyof Contact)[]>([]);
  const [searchActive, setSearchActive] = useState(false);
  const [results, setResults] = useState(list);
  const [isOpenNewConv, setOpenNewConv] = useState<boolean>(false);

  const intl = useIntl();

  const handleSearch = async (
    event: CustomEvent<{ value?: string | null | undefined }>
  ) => {
    if (event.detail.value) {
      const { data, err } = await ContactsApi.search(event.detail.value);
      if (data) {
        setSearchActive(true);
        setResults(
          data.map((d) => ({
            ...d,
            type: d.igId ? "ig" : d.fmId ? "fm" : d.calryId ? "ab" : "wa",
          }))
        );
      }
      console.log(err);
    } else {
      setSearchActive(false);
    }
  };

  const isWaReady = settings?.onboardingList?.find(
    (channel) => channel.name === "wa"
  )?.done;

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <FormattedMessage id="nav.inbox" />
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true} className="chat-mobile-wrapper">
          <div className="chat-interface">
            <div className="chat-interface-nav">
              <div className="flex gap-2 align-items-center searchbar-container">
                <IonSearchbar
                  debounce={100}
                  placeholder={intl.formatMessage({
                    id: "chats.searchContact",
                  })}
                  onIonInput={(ev) => handleSearch(ev)}
                  className={classNames({
                    "search-is-active": searchActive === true,
                  })}
                  inputmode={`text`}
                  mode={`ios`}
                  style={{ zIndex: 5000 }}
                  showCancelButton="focus"
                  onIonFocus={(e) => {
                    setSearchActive(true);
                  }}
                  onIonClear={(e) => {
                    setSearchActive(false);
                    setResults([]);
                  }}
                  onIonBlur={(e) => {
                    if (!results.length) {
                      setSearchActive(false);
                    }
                  }}
                />
                {!searchActive && isWaReady && (
                  <>
                    <IconPlus
                      role="button"
                      color="var(--visito-color-1)"
                      size={26}
                      onClick={() => setOpenNewConv(true)}
                    />
                    <Suspense fallback="Loading...">
                      <NewConversation
                        isOpen={isOpenNewConv}
                        onOpenChange={setOpenNewConv}
                        id="new-conversation-mobile-modal"
                      />
                    </Suspense>
                  </>
                )}
              </div>
              <div
                className="chat-interface-nav-review fw-semibold-1"
                style={{
                  display: searchActive ? "none" : "block",
                }}
              >
                <IonChip
                  onClick={() => setFilters([])}
                  color={!filters.length ? undefined : "medium"}
                >
                  <FormattedMessage id="chats.all" />
                </IonChip>
                <IonChip
                  onClick={() => setFilters(["escalated"])}
                  color={filters.includes("escalated") ? undefined : "medium"}
                >
                  <FormattedMessage id="chats.escalated" /> ({escalatedCount})
                </IonChip>
                <IonChip
                  onClick={() => setFilters(["manual"])}
                  color={filters.includes("manual") ? undefined : "medium"}
                >
                  <FormattedMessage id="chats.manual" />
                </IonChip>
              </div>
            </div>

            {loading ? (
              <SkeletonLoader rows={10} />
            ) : (
              <ChatList
                contacts={searchActive ? results : contacts}
                mode={"mobile"}
                //changeStatus={changeStatusContact}
                isSearch={searchActive}
                filters={filters}
              />
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

interface ILayoutProps {
  contacts: Contact[];
  escalatedCount: number;
  dispatch: Function;
  loading: boolean;
  settings: Settings;
}

export default connect((props: any) => ({
  loading: props.events.loading,
  contacts: props.contacts.contactList,
  escalatedCount: props.contacts.escalatedCount,
  settings: props.app.settings,
}))(Chats);
