import { useEffect } from "react";
import { PushNotifications } from "@capacitor/push-notifications";
import AuthApi from "../api/auth";

const PushNotifcations: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { request, setEvent } = props;
  useEffect(() => {
    if (request) {
      const registerNotifications = async () => {
        await PushNotifications.addListener("registration", async (token) => {
          if (token.value) {
            const { err } = await AuthApi.push({
              active: true,
              token: token.value,
            });

            if (err) {
              console.log(err);
            }
          }
          console.log("Registration token: ", token.value);
        });

        await PushNotifications.addListener("registrationError", (err) => {
          console.log("Registration error: ", err.error);
        });

        await PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            console.log("Push notification received: ", notification);
          }
        );

        await PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (action) => {
            const { notification } = action;
            const { type, payload } = notification.data;
            setEvent({
              timeStamp: new Date().toString(),
              data: JSON.parse(payload),
              type,
              companyId: `undefined`,
            });
          }
        );
        let permStatus = await PushNotifications.checkPermissions();
        if (permStatus.receive === "prompt") {
          permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== "granted") {
          throw new Error("User denied permissions!");
        }
        await PushNotifications.register();
      };
      registerNotifications();
    }
  }, [request]);
  useEffect(() => {}, []);
  return <div></div>;
};

interface ILayoutProps {
  request: boolean;
  setEvent: Function;
}

export default PushNotifcations;
