const serialize = function (obj, flag = false) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && p !== "pathname") {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const deserialize = function (string) {
  if (string) {
    const str = string.substring(1);
    return JSON.parse(
      '{"' +
        decodeURI(str)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  }
  return {};
};

export default serialize;
