import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";

const UpdateAppVersion: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  return (
    <IonPage id="main-content">
      <IonContent fullscreen color="light">
        Download New App Download New App Download New App Download New App
        Download New App
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {}

export default connect((props: any) => ({}))(UpdateAppVersion);
