import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { IonRouterOutlet, isPlatform } from "@ionic/react";
import GA from "../../components/GoogleAnalytics";
import NavBarAuth, { FooterAuth } from "../../components/NavBarAuth";

import Login from "./Login";
import Register from "./Register";
import RegisterCloudbeds from "./RegisterCloudbeds";
import ForgotPassword from "./Forgot";
import ResetPassword from "./Reset";
import RegisterUser from "./RegisterUser";

import { setLanguage } from "../../actions/app";
const HOCAuth: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { language } = props;
  const switchLanguage = async (language: string) => {
    props.dispatch(setLanguage(language));
  };

  return (
    <>
      <NavBarAuth hide={isPlatform("android") || isPlatform("ios")} />
      <GA />
      <IonRouterOutlet>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/register" component={Register} />
        <Route path="/auth/register-cloudbeds" component={RegisterCloudbeds} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
        <Route path="/auth/reset-password" component={ResetPassword} />
        <Route
          path="/auth/register-user"
          render={(routeProps) => (
            <RegisterUser {...routeProps} language={language} />
          )}
        />
      </IonRouterOutlet>
      <FooterAuth
        hide={isPlatform("android") || isPlatform("ios")}
        switchLanguage={switchLanguage}
        language={language}
      />
    </>
  );
};

interface ILayoutProps {
  language: string;
  dispatch: Function;
}
export default connect((props: any) => ({
  user: props.app.user,
}))(HOCAuth);
