import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react"

import SkeletonLoader from "../../../SkeletonLoader";
import { FormattedMessage } from "react-intl";
import { OutboundEvent, OutboundEventStats, Location } from "../../../../interfaces";

import Last30Activity from "../../../Last30Activity";
import EmptyState from "../../empty";
import { MobileList } from "../../list";
import LocationsSelect from "../../../common/locations-select";

import headerStyles from "../../../../theme/header.module.css";
import styles from "../../styles.module.css";

const MobileTitle = () => (
  <IonHeader>
    <IonToolbar>
      <IonTitle>
        <FormattedMessage id="nav.outbound" />
      </IonTitle>
    </IonToolbar>
  </IonHeader>
);

const MobileHeader = () => (
  <div>
    <div className={headerStyles.header}>
      <h1 className={headerStyles.mobileTitle}>
        <FormattedMessage id="outbound.pageTitle" />
      </h1>
      <h3 className={headerStyles.mobileDescription}>
        <FormattedMessage id="outbound.pageDescription" />
      </h3>
    </div>
  </div>
);

type MobileEventsProps = {
  loading: boolean;
  total: number;
  outbound?: {
    events: OutboundEvent[];
    locations: Location[];
  },
  stats?: OutboundEventStats[];
  currentLocation: string;
  onChangeLocation: (location: string) => void;
}

const MobileEvents = ({ loading, outbound, stats, total, currentLocation, onChangeLocation }: MobileEventsProps) => {
  return (
    <IonPage>
      <MobileTitle />
      <IonContent fullscreen color="light" className="ion-padding">
        <div className="view-wrapper">
        {loading ? (
          <SkeletonLoader rows={6} />
        ) : outbound?.events?.length ? (
          <>
            <div className="view-pane">
              <MobileHeader />
              {outbound?.locations && outbound.locations.length > 1 && (
                <LocationsSelect
                  value={currentLocation}
                  onChange={onChangeLocation}
                  options={outbound.locations}
                />
              )}
              <div className="view-pane-body mt-3">
                <p className="mb-0 pb-1 fs-2 fw-semibold-0">
                  <FormattedMessage id="outboud.messagesSent" />
                </p>
                <p className="mb-2 fs-6 fw-bold">
                  {Intl.NumberFormat("en-US").format(total)}
                </p>
                <Last30Activity stats={stats || []} />
              </div>
            </div>

            <div className="view-pane">
              <div className={`${styles.divider}`} />
            </div>
            <MobileList events={outbound.events || []} />
          </>
        ) : (
          <EmptyState isMobile />
        )}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default MobileEvents;
