import { createReducer } from "@reduxjs/toolkit";
import { setEvent, setSocket, setLoaded } from "../actions/events";

import { Event, Socket } from "../interfaces";

const event: Event = {
  timeStamp: "date",
  companyId: "",
  type: "default",
};

const socket: Socket = {
  connected: false,
};

const loading = true;

export const Events = createReducer(
  { loading, event, socket },
  {
    [setEvent.type]: (state, action) => ({
      ...state,
      event: {
        timeStamp: action.payload.event.timeStamp,
        companyId: action.payload.event.companyId,
        userId: action.payload.event.userId,
        type: action.payload.event.type,
        data: action.payload.event.data,
      },
    }),
    [setLoaded.type]: (state, action) => ({
      ...state,
      loading: false,
    }),
    [setSocket.type]: (state, action) => ({
      ...state,
      socket: action.payload.socket,
    }),
  }
);
