import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import "./Last30Activity.css";

import { OutboundEventStats } from "../interfaces";

const Last30Activity: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { stats } = props;

  const options = {
    chart: {
      type: "areaspline",
      styleMode: true,
    },
    title: { text: "" },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      visible: false,
      type: "category",
      labels: {
        overflow: "justify",
      },
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 3,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: [
      {
        type: "areaspline",
        fillOpacity: 0.4,
        name: "value",
        data: stats.map((stat) => [stat.date, stat.count]),
        color: "#375DFB",
        animation: {
          duration: 1500,
        },
      },
    ],
    legend: { enabled: false },
  };

  return (
    <div className="chart-container">
      <div style={{ height: "200px" }}>
        {stats.length ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ className: "chart-div" }}
          />
        ) : null}
      </div>
    </div>
  );
};

interface ILayoutProps {
  stats: OutboundEventStats[];
}

export default Last30Activity;
