import { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { IonPage, IonContent } from "@ionic/react";
import { FormattedMessage } from "react-intl";
import SkeletonText from "../../../components/SkeletonText";
import TextEditor from "../../../components/TextEditor";

// Instructions

import { IconArrowNarrowLeft } from "@tabler/icons-react";

// Api
import AiApi from "../../../api/ai";

// CSS
import "@mdxeditor/editor/style.css";
import "./AiSettingsInstructions.css";

interface AiSetting {
  limit: number;
  text: string;
}

const AiSettings: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");

  const [ai, setAi] = useState<AiSetting>({
    limit: 0,
    text: "",
  });

  useEffect(() => {
    setType(props.match.params.type);
  }, [props.match.params.type]);

  useEffect(() => {
    if (type) {
      requestAiSettings(type);
    }
  }, [type]);

  const setAiValues = (data: any, type: string) => {
    if (data) {
      switch (type) {
        case "general":
          setAi({ ...data.aiSettings.context.general });
          break;
        case "customer":
          setAi({ ...data.aiSettings.context.customer });
          break;
      }
    }
  };

  const requestAiSettings = async (type: string) => {
    setLoading(true);
    const { data, err } = await AiApi.getAiSettings();
    if (data) {
      setAiValues(data, type);
    }
    if (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const cancel = async () => {
    requestAiSettings(type);
  };
  const updateContext = async (content: string, cb: Function) => {
    const { err } = await AiApi.putAiSettingsByField(type, content);
    cb({ err: err ? err.err : null });
  };
  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper container">
          <div className="text-editor-container">
            <div className="breadcrumb-visito">
              <Link to="/ai-settings">
                <IconArrowNarrowLeft />{" "}
                <FormattedMessage id="ai-settings-intructions.title" />
              </Link>
            </div>
            <div className="text-holder-editor">
              {loading ? (
                <SkeletonText rows={17} />
              ) : (
                <TextEditor
                  limit={ai.limit}
                  content={ai.text}
                  onSave={updateContext}
                  cancel={cancel}
                />
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps
  extends RouteComponentProps<{
    type: string;
  }> {}

export default AiSettings;
