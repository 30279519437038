import React from "react";

interface Placeholder {
  key: string;
  value: string;
}

interface PlaceholderReplacerProps {
  template: string;
  placeholders: Placeholder[];
}

const PlaceholderReplacer: React.FC<PlaceholderReplacerProps> = ({
  template,
  placeholders,
}) => {
  const replacePlaceholders = (text: string, values: Placeholder[]): string => {
    return text.replace(/{{([^}]+)}}/g, (_, key) => {
      const valueObj = values.find((v) => v.key === key);
      return valueObj ? valueObj.value : "";
    });
  };

  const applyFormatting = (text: string): React.ReactNode => {
    const parts = text.split(/(\*[^*]+\*|_[^_]+_|~[^~]+~)/g);

    return parts.map((part, index) => {
      const boldMatch = part.match(/^\*(.+)\*$/);
      const italicMatch = part.match(/^_(.+)_$/);
      const strikethroughMatch = part.match(/^~(.+)~$/);

      if (boldMatch) {
        return (
          <span className="fw-semibold" key={index}>
            {boldMatch[1]}
          </span>
        );
      }

      if (italicMatch) {
        return (
          <span style={{ fontStyle: "italic" }} key={index}>
            {italicMatch[1]}
          </span>
        );
      }

      if (strikethroughMatch) {
        return (
          <span style={{ textDecoration: "line-through" }} key={index}>
            {strikethroughMatch[1]}
          </span>
        );
      }

      return <span key={index}>{part}</span>;
    });
  };

  const getReplacedText = (
    template: string,
    values: Placeholder[]
  ): React.ReactNode => {
    const replacedTemplate = replacePlaceholders(template, values);
    const lines = replacedTemplate.split("\n");

    return lines.map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {applyFormatting(line)}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <span style={{ display: "block" }} className="mb-0">
      {getReplacedText(template, placeholders)}
    </span>
  );
};

export default PlaceholderReplacer;
