import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import PlaygroundApi from "../api/playground";

const generateRandomToken = () => {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
};

export type Playground = {
  webId: string | null | undefined;
  contactId: string | null | undefined;
  companyId: string | null | undefined;
  name?: string;
}

export type Playgrounds = {
  loading: boolean;
  playgrounds?: Playground[];
}

interface PlaygroundState extends Playgrounds {
  setPlaygrounds: (playgrounds: Playgrounds) => void;
  setLoading: (loading: boolean) => void;
  createWebId: (companyId: string, name?: string, isAuth?: boolean) => void;
  reset: () => void;
};

const playgroundInitialState: Playgrounds = {
  loading: false,
  playgrounds: undefined,
};

const usePlaygroundStore = create(
  persist(
    devtools<PlaygroundState>(
      (set, get) => ({
        ...playgroundInitialState,
        setPlaygrounds: (playgrounds) => {
          return set(playgrounds)
        },
        setLoading: (loading) => {
          return set({ loading })
        },
        createWebId: async (companyId, name = "Playground User", isAuth = true) => {
          const state = get() || {};
          const companyPlayground = state?.playgrounds?.find((play) => play.companyId === companyId);

          if (companyPlayground && companyPlayground.contactId && companyPlayground.webId) {
            return set({ loading: false })
          }

          const webId = generateRandomToken();
          const { data } = isAuth ? await PlaygroundApi.createWebId({ webId, name }) : await PlaygroundApi.createFreeContact({ webId, companyId, name });
          if (data?.playground.contactId) {
            const newPlaygrounds = [...state.playgrounds || [], { webId, contactId: data.playground.contactId, companyId, name }];
            return set({ playgrounds: newPlaygrounds, loading: false })
          }

          return;
        },
        reset: () => set({ ...playgroundInitialState })
      }),
      { name: "usePlaygroundStore" }
    ),
    { name: "playgrounds" }
  )
);

export const usePlaygroundData = (companyId?: string) =>
  usePlaygroundStore((state) => ({
    playgrounds: state.playgrounds,
    playground: state?.playgrounds?.find((play) => play.companyId === companyId),
    loading: state.loading,
  }));

export const usePlaygroundActions = () =>
  usePlaygroundStore((state) => ({
    setPlaygrounds: state.setPlaygrounds,
    setLoading: state.setLoading,
    createWebId: state.createWebId,
    reset: state.reset,
  }))

export default usePlaygroundStore;

