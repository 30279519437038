import config from "../config";
import request, { requestOptions } from "../utils/request";
const baseUrl = `${config.API_ROOT}/app/settings`;

const Settings = {
  get() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/`;
    return request(url, options);
  },
};

export default Settings;
