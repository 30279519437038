import { IconClock, IconCreditCard } from "@tabler/icons-react";
import { IonHeader, IonToolbar } from "@ionic/react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Settings } from "../interfaces";
import classNames from "classnames";
import "./NavBanner.css";

const NavBanner: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { trial, payment } = props.settings.status;
  const { mode, hide } = props;

  return mode === "desktop" ? (
    payment.active ? null : (
      <div
        className={classNames("navbanner", {
          trial: trial,
          payment: !trial && !payment.active,
        })}
      >
        <div className="navbanner-item">
          {trial ? <IconClock size={16} /> : <IconCreditCard size={16} />}
        </div>
        <div className="navbanner-item">
          <span className="fs-2 fw-semibold-1">
            {trial ? (
              <>
                <FormattedMessage
                  id="nav.trial"
                  values={{
                    count: trial,
                  }}
                />{" "}
                <Link to="/settings/payment" className="link-unset fw-semibold">
                  <FormattedMessage id="nav.paymentCTA" />
                </Link>
              </>
            ) : (
              <>
                <FormattedMessage id="nav.payment" />{" "}
                <Link to="/settings/payment" className="link-unset fw-semibold">
                  <FormattedMessage id="nav.paymentCTA" />
                </Link>
              </>
            )}
          </span>
        </div>
      </div>
    )
  ) : hide ? null : payment.active ? null : (
    <IonHeader>
      <IonToolbar
        className={classNames("navbanner", {
          trial: trial,
          payment: !trial && !payment.active,
        })}
      >
        <Link to="/settings/payment" className="link-unset">
          <div className="navbanner">
            <div className="navbanner-item">
              {trial ? <IconClock size={16} /> : <IconCreditCard size={16} />}{" "}
            </div>
            <div className="navbanner-item">
              {trial ? (
                <FormattedMessage
                  id="nav.trial"
                  values={{
                    count: trial,
                  }}
                />
              ) : (
                <>
                  <FormattedMessage id="nav.payment" />
                </>
              )}
            </div>
          </div>
        </Link>
      </IonToolbar>
    </IonHeader>
  );
};

interface ILayoutProps {
  settings: Settings;
  mode: string;
  hide?: boolean;
}

export default NavBanner;
