import React from "react";
import { connect } from "react-redux";
import { IonContent, IonPage, useIonToast } from "@ionic/react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { IconRefresh, IconLink } from "@tabler/icons-react";

import ProgressBar from "react-bootstrap/ProgressBar";

import AiApi from "../../api/ai";
import PlaygroundApi from "../../api/playground";
import SkeletonText from "../SkeletonText";
// import Alert from "../common/alert";
import AiTextContext from "./text-context";
import ChatDetail from "../ChatDetail";
import { makeSelectContactsDB, makeSelectUser } from "../../selectors";
import { usePlaygroundData } from "../../store/playground";
import { Event } from "../../interfaces";
import ChatInput from "../common/chat-input";
import { setRequest } from "../../actions/requests";

import CrawlingSvg from "../../theme/images/crawling.svg";
import headerStyles from "../../theme/header.module.css";
import styles from "./styles.module.css";

interface SocketEventMessage {
  progress: number;
  working: boolean;
  text: string;
}

function AiSettings({ event, dispatch }: { event: Event; dispatch: Function }) {
  const [content, setContent] = React.useState<string>();
  const [status, setStatus] = React.useState<SocketEventMessage>({
    progress: 0,
    working: false,
    text: "",
  });
  const intl = useIntl();
  const { company } = useSelector(makeSelectUser());
  const { playground } = usePlaygroundData(company.id);
  const selectedChat = useSelector(
    makeSelectContactsDB(String(playground?.contactId))
  );
  const [present] = useIonToast();
  const { data: { data: aiData } = {}, isLoading: isGettingAi } = useQuery({
    queryFn: () => AiApi.getAiSettings(),
    queryKey: ["getAiSettings"],
    refetchOnWindowFocus: false,
  });

  const {
    data: sendResponse,
    mutate: mutateSendMessage,
    isPending: isSendingMessage,
  } = useMutation({
    mutationFn: PlaygroundApi.sendMessage,
    onSuccess: (data) => {
      if (!data.ok) {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      }
    },
    onError: () => {
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    },
  });

  const { mutate: mutateClearPlayground, isPending: isClearingPlayground } =
    useMutation({
      mutationFn: PlaygroundApi.clearPlayground,
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      },
    });

  const onChangeContent = (newContent: string) => setContent(newContent);

  const onBeforeClearPlayground = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (playground?.contactId) {
      mutateClearPlayground({ contactId: playground.contactId });
    }
  };

  const onSubmit = () => {
    if (content && playground?.contactId) {
      mutateSendMessage({ contactId: playground.contactId, content });
    }
  };

  const onCopyPlayground = async () => {
    await navigator.clipboard.writeText(
      `https://app.visitoai.com/web-chat?slug=${company?.slug}`
    );
    return present({
      message: intl.formatMessage({ id: "common.copyLink" }),
      duration: 6000,
      color: "light",
      position: "top",
    });
  };

  React.useEffect(() => {
    if (sendResponse && sendResponse?.ok) {
      setContent("");
    }
  }, [sendResponse]);

  React.useEffect(() => {
    if (
      event.type === "company-message" &&
      event.data?.task === "ai-settings"
    ) {
      const message: SocketEventMessage = {
        ...event.data?.message?.context,
      };
      setStatus({
        working: message.working,
        progress: message.progress,
        text: message.text,
      });
    }
  }, [event]);

  React.useEffect(() => {
    if (playground?.contactId) {
      dispatch(setRequest({ contactId: playground.contactId }));
    }
  }, [playground?.contactId]);

  const isWorking = status.working;

  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper">
          <div className={styles.playgroundWrapper}>
            <div className={styles.contextManagementWrapper}>
              <div>
                <h1 className={headerStyles.pageTitle}>
                  <FormattedMessage id="aiSettingsKnowledge" />
                </h1>
                <h3 className={headerStyles.pageDescription}>
                  <FormattedMessage id="aiSettingsAlert" />
                </h3>
              </div>

              {isGettingAi ? (
                <SkeletonText rows={17} />
              ) : isWorking ? (
                <div className={styles.loaderWrapper}>
                  <div className={styles.loaderContainer}>
                    <div className="mb-3">
                      <img alt="" src={CrawlingSvg}></img>
                    </div>
                    <div className="color-step-900 font-14 fw-semibold-1">
                      <FormattedMessage id="aiSettingsWorking" />
                    </div>
                    <div className="mt-2">
                      <ProgressBar
                        now={status.progress * 100}
                        className="visito-progress-bar"
                      />
                    </div>
                  </div>
                </div>
              ) : aiData ? (
                <AiTextContext
                  limit={aiData?.aiSettings?.context?.general?.limit || 0}
                  text={
                    aiData?.aiSettings?.context?.general?.text || status.text
                  }
                />
              ) : null}
            </div>
            {!selectedChat.contact.id ||
            selectedChat.contact.id.length === 0 ? (
              <div className={styles.chatWrapper}>
                <div>
                  <SkeletonText rows={14} />
                </div>
              </div>
            ) : (
              <div className={styles.chatWrapper}>
                {selectedChat.messages.length > 0 && (
                  <div className={styles.chatHeaderContainer}>
                    <div className={styles.chatHeaderContent}>
                      <div className="flex gap-3 align-items-center">
                        <h3 className="fs-4 mb-0">Playground</h3>
                        <button
                          className={`${styles.resetBtn} p-1 px-2 fs-2 gap-1`}
                          onClick={onCopyPlayground}
                        >
                          <IconLink size={17} />
                          <FormattedMessage id="common.share" />
                        </button>
                      </div>
                      <button
                        className={styles.resetBtn}
                        onClick={onBeforeClearPlayground}
                        disabled={isClearingPlayground}
                      >
                        <IconRefresh size={17} />
                        <FormattedMessage id="aiSettingsReset" />
                      </button>
                    </div>
                  </div>
                )}
                <div className="h-100 w-100">
                  <ChatDetail
                    messages={selectedChat.messages}
                    openImage={(link) => console.log("Open image", link)}
                    contactId={selectedChat.contact.id}
                    mode={"desktop"}
                    scrollEscalations={1}
                    type="playground"
                    companySlug={company?.slug}
                  />
                </div>
                <div
                  className={`${styles.chatInputContainer} ${
                    selectedChat.messages.length === 0 ? "pt-3" : ""
                  }`}
                >
                  <ChatInput
                    id="send-body-playground"
                    value={content}
                    placeholder={intl.formatMessage({
                      id: "chats.newMessageInput",
                    })}
                    enableWaActions={false}
                    onInputChange={onChangeContent}
                    onBeforeSubmit={onSubmit}
                    //disabled={isSendingMessage || isWorking}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default connect((props: any) => ({}))(AiSettings);
