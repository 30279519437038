import { AsYouType } from "libphonenumber-js";

import { parseDateTimeZone, parseDate } from "./moment";

export const adaptOutboundEvents = (outboundEvents) => outboundEvents.map((e) => ({
  id: e._id,
  name: e.name,
  active: e.active,
  event: e.event,
  sendAt: e.sendAt,
  template: e.template,
}));

export const adaptStats = (stats) => stats.map(
  (e) => ({
    date: e.date,
    count: e.count,
  }),
)

const adaptTemplates = (templates) => {
  return templates.map((temp) => ({
    ...temp,
    components: temp.components.map((comp) => {
      if (comp.type !== "BODY") {
        return comp;
      }
      return {
        ...comp,
        text: comp.parameters && comp.parameters.length > 0 ? comp.text.replace(/{{(\d+)}}/g, (_, index) => {
          const keyObj = comp.parameters[parseInt(index, 10) - 1];
          const key = keyObj ? keyObj.text : '';
          return `{{${key}}}`;
        }) : comp.text,
      }
    })
  }))
}

const adaptOutboundLogs = (outboundEventLogs, outboundEvent) => {
  return {
    logs: outboundEventLogs.map((e) => ({
      timestamp: parseDateTimeZone(e.createAt, "DD/MM/YYYY HH:mm"),
      id: e._id,
      message: e.message ? e.message : null,
      outboundEvent: {
        id: e.eventOutboundLocation._id,
        name: e.eventOutboundLocation.name,
        active: e.eventOutboundLocation.active,
      },
      booking: e.booking
        ? {
            fullName: e.booking.fullName,
            id: e.booking._id,
            source: e.booking.source,
            checkIn: parseDate(e.booking.from, "DD/MM/YYYY"),
            checkOut: parseDate(e.booking.to, "DD/MM/YYYY"),
            phoneNumber: new AsYouType().input(`+${e.booking.phoneNumber}`),
            preCheckIn: e.booking.preCheckIn,
            locationName: e.booking.locationName,
            contactId: e.booking?.contact || null,
          }
        : {},
      contact: e.contact
        ? {
            name: e.contact.fullName,
            phoneNumber: new AsYouType().input(`+${e.contact.phoneNumber}`),
          }
        : {},
    })),
    outboundEvent: {
      id: outboundEvent
        ? outboundEvent._id
        : "",
      name: outboundEvent
        ? outboundEvent.name
        : "",
      active: outboundEvent
        ? outboundEvent.active
        : false,
      event: outboundEvent?.event || "",
      sendAt: outboundEvent?.sendAt || "",
      template: outboundEvent?.template || "",
      templates: outboundEvent.templates ? adaptTemplates(outboundEvent.templates) : undefined,
    },
  }
}

export default adaptOutboundLogs;
