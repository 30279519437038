export function validateMessage(input) {
  // Remove all spaces and line breaks from the input
  const cleanedInput = input.replace(/\s/g, '');

  // Check if the cleaned input is empty
  if (cleanedInput === '') {
    return { err: true }; // Input is empty
  }

  return { err: false };
}