import config from "../config";
import request, { requestOptions } from "../utils/request";
const baseUrl = `${config.API_ROOT}/app/home`;

const Home = {
  get({ date }: { date: string }) {
    const options = requestOptions("GET", {}, true);
    const url = new URL(`${baseUrl}/`);

    if (date) {
      url.searchParams.set("date", date);
    }

    return request(url, options);
  },
};

export default Home;
