import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}/channels`;

const Channels = {
  getChannels() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/`;
    return request(url, options);
  },
  createChannels(body) {
    const options = requestOptions("POST", body, true);
    const url = `${baseUrl}/`;
    return request(url, options);
  },
  toggleChannels(id, active) {
    const options = requestOptions("PUT", { active }, true);
    const url = `${baseUrl}/${id}`;
    return request(url, options);
  },
};

export default Channels;
