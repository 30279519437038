import React from "react";
import { FormattedMessage } from "react-intl";
import Dropdown from "react-bootstrap/Dropdown";
import CustomToggle from "./CustomToggle";

interface InvitedUser {
  id: string;
  email: string;
  role: string;
  accepted: boolean;
  createdAt: string;
}

const InvitedList: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { list, onDelete } = props;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div>
      <table className="table ">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="invitedList.emailLabel" />
            </th>
            <th>
              <FormattedMessage id="invitedList.roleLabel" />
            </th>
            <th>
              <FormattedMessage id="invitedList.dateLabel" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.map((user, index) => (
            <tr key={index}>
              <td>{user.email}</td>
              <td>
                <span
                  className={`badge ${
                    !user.accepted
                      ? "text-bg-warning"
                      : user.role === "admin"
                      ? "text-bg-success"
                      : "text-bg-light"
                  }`}
                >
                  {!user.accepted ? "Pending" : user.role}
                </span>
              </td>
              <td>{formatDate(user.createdAt)}</td>
              <td style={{ textAlign: "right" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-custom-components"
                    as={CustomToggle}
                  >
                    Custom toggle
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => onDelete(user.id)}
                    >
                      {user.accepted ? (
                        <FormattedMessage id="common.remove" />
                      ) : (
                        <FormattedMessage id="common.cancel" />
                      )}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface ILayoutProps {
  list: InvitedUser[];
  onDelete: (_id: string) => void;
}

export default InvitedList;
