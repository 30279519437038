import { IonItem, IonLabel, IonList, IonToggle } from "@ionic/react";
import { FormattedMessage } from "react-intl";
import { IconClockHour3 } from "@tabler/icons-react";

import { OutboundEvent } from "../../../interfaces";
import { EventStatus } from "../logs/badge";
import { Link } from "react-router-dom";

import styles from "../styles.module.css";

export const MobileList = ({ events }: { events: OutboundEvent[] }) => (
  <IonList inset className="mt-0">
    {events.map((event) => (
      <IonItem
        routerLink={`/outbound/${event.id}`}
        detail
        lines="full"
        button
        key={event.id}
      >
        <IonLabel className="ion-text-wrap">
          <div className="flex flex-column gap-2 py-2">
            <div className="flex gap-2 justify-content-start">
              <p className="mb-0 fw-semibold fs-4">{event.name}</p>
            </div>
            <p className={`${styles.eventDescription} fs-3 fw-semibold-0`}>
              <FormattedMessage id={`outbound.${event.event}`} />
            </p>
            <div className="flex align-items-center gap-1">
              <IconClockHour3 className={styles.timeIcon} size={16} />
              <p
                className={`${styles.eventDescription} mb-0 fs-2 fw-semibold-0`}
              >
                <FormattedMessage id={`outbound.time_${event.event}`} />
              </p>
            </div>
          </div>
        </IonLabel>
        <div slot="end">
          <EventStatus active={event.active} />
        </div>
      </IonItem>
    ))}
  </IonList>
);

const List = ({
  events,
  onDisplayAlert,
}: {
  events: OutboundEvent[];
  onDisplayAlert: (checked: boolean, id: string) => void;
}) => (
  <>
    {events.map((event) => (
      <div className="col-md-4" key={event.id}>
        <div className="visito-card visito-block h-100 relative">
          <div className="visito-card-body flex flex-column justify-content-between h-100">
            <div className="flex gap-2 mb-1 justify-content-between">
              <p className="mb-0 fw-semibold">{event.name}</p>
              <EventStatus active={event.active} />
            </div>
            <p className={`${styles.eventDescription} mb-2 fs-3 fw-semibold-0`}>
              <FormattedMessage id={`outbound.${event.event}`} />
            </p>
            <div className="flex align-items-start gap-1 mb-3">
              <IconClockHour3 className={styles.timeIcon} size={18} />
              <p
                className={`${styles.eventDescription} mb-0 fs-2 fw-semibold-0`}
              >
                <FormattedMessage id={`outbound.time_${event.event}`} />
              </p>
            </div>
            <div className={styles.divider} />
            <div className="flex justify-content-between gap-1 mt-4">
              <p className="mb-0 fs-2 fw-semibold-0">Status</p>
              <IonToggle
                checked={event.active}
                onIonChange={(ev) =>
                  onDisplayAlert(ev.detail.checked, event.id)
                }
                mode="ios"
                className="ios visito-toggle"
              />
            </div>
            <div className="mt-4 flex align-items-center">
              <Link
                className="btn btn-sm btn-visito-outline w-100"
                to={`/outbound/${event.id}`}
              >
                <FormattedMessage id="outbound.viewEvent" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

export default List;
