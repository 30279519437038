import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Location } from "history";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import { FormattedMessage, useIntl } from "react-intl";

import {
  IconBrandInstagram,
  IconBrandWhatsapp,
  IconBrandMessenger,
} from "@tabler/icons-react";

import Api from "../../../../api/channels";

import FacebookSdk from "../../../../components/FacebookSdk";
import SkeletonLoader from "../../../../components/SkeletonLoader";
import CustomToggle from "../../../../components/CustomToggle";

const Channels: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const intl = useIntl();

  interface Whatsapp {
    id: string;
    phoneNumberId: string;
    active: boolean;
    phoneNumber: string;
  }

  interface Instagram {
    id: string;
    name: string;
    active: boolean;
  }

  interface Messenger {
    pageId: string;
    name: string;
    active: boolean;
  }

  const [whatsapp, setWhatsapp] = useState<Whatsapp[]>([]);
  const [instagram, setInstagram] = useState<Instagram[]>([]);
  const [messenger, setMessenger] = useState<Messenger[]>([]);
  const [loading, setLoading] = useState(true);

  const requestChannels = async () => {
    setLoading(true);
    const { data, err } = await Api.getChannels();
    if (data) {
      const { channels } = data;
      setWhatsapp(channels.wa || []);
      setInstagram(channels.ig || []);
      setMessenger(channels.fm || []);
      setLoading(false);
    }
    if (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const createChannels = async (meta: any) => {
    console.log(meta);
    const { data, err } = await Api.createChannels({ meta });
    console.log(data, err);
    requestChannels();
  };

  const toggleChannel = async (id: string, active: boolean) => {
    const { err } = await Api.toggleChannels(id, active);
    if (err) {
      console.log(err);
    }
  };

  const toggleWa = (w: Whatsapp) => {
    toggleChannel(w.phoneNumberId, !w.active);
    const newWa = whatsapp.map((wa) => {
      if (wa.phoneNumberId === w.phoneNumberId) {
        wa.active = !wa.active;
      }
      return wa;
    });
    setWhatsapp(newWa);
  };

  const toggleInsta = (i: Instagram) => {
    toggleChannel(i.id, !i.active);
    const newIg = instagram.map((ig) => {
      if (ig.id === i.id) {
        ig.active = !ig.active;
      }
      return ig;
    });
    setInstagram(newIg);
  };

  const toggleMessenger = (m: Messenger) => {
    toggleChannel(m.pageId, !m.active);
    const newFm = messenger.map((fm) => {
      if (fm.pageId === m.pageId) {
        fm.active = !fm.active;
      }
      return fm;
    });
    setMessenger(newFm);
  };

  const onLoginSucces = () => {
    setLoading(true);
  };

  useEffect(() => {
    requestChannels();
  }, []);

  const FBMessenger = (
    <div className="visito-card visito-block">
      <div className="visito-card-header">
        <div className="visito-card-title">
          <IconBrandMessenger width={22} style={{ marginTop: "-2px" }} />{" "}
          Messenger
        </div>
      </div>
      <div className="visito-card-body">
        {loading ? (
          <SkeletonLoader rows={1} />
        ) : messenger.length ? (
          <table className="table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="channels.account" />{" "}
                </th>
                <th>
                  <FormattedMessage id="channels.autoReplies" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {messenger.map((i) => (
                <tr key={i.pageId}>
                  <td>{i.name}</td>

                  <td>
                    <span
                      className={classNames("badge", {
                        "text-bg-success": i.active,
                        "text-bg-light": !i.active,
                      })}
                    >
                      {i.active ? "auto" : "manual"}
                    </span>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-custom-components"
                        as={CustomToggle}
                      >
                        Custom toggle
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => toggleMessenger(i)}
                          eventKey="1"
                        >
                          {i.active ? (
                            <FormattedMessage id="common.pause" />
                          ) : (
                            <FormattedMessage id="common.activate" />
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>
            <p>
              <FormattedMessage id="channels.fmTitle" />
            </p>
            <small className="color-step-600">
              <p>
                <FormattedMessage id="channels.fmDescription" />
              </p>
            </small>
            <div>
              <FacebookSdk
                onLoginSucces={() => onLoginSucces()}
                onCodeSucces={createChannels}
                className="btn btn-sm btn-flex btn-visito-primary mt-2"
                config={{
                  id: "1389965081634294",
                  type: "user",
                  platform: "fm",
                }}
                text="Connect Messenger"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const empty = (
    <div>
      <p>
        <FormattedMessage id="channels.igTitle" />
      </p>
      <small className="color-step-600">
        <FormattedMessage id="channels.igDescription" />
      </small>
      <div>
        <FacebookSdk
          onLoginSucces={() => onLoginSucces()}
          onCodeSucces={createChannels}
          className="btn btn-sm btn-visito-primary mt-2"
          config={{ id: "363287656104000", type: "user", platform: "ig" }}
          text="Connect Instagram"
        />
      </div>
    </div>
  );

  const emptyWa = (
    <div>
      <p>
        <FormattedMessage id="channels.waTitle" />
      </p>
      <small className="color-step-600">
        <FormattedMessage id="channels.waDescription" />
      </small>
      <div>
        <FacebookSdk
          onLoginSucces={() => onLoginSucces()}
          onCodeSucces={createChannels}
          className="btn btn-sm btn-flex btn-visito-primary mt-2"
          config={{
            id: "1389334938656203",
            type: "system",
            platform: "wa",
          }}
          text="Connect WhatsApp"
        />
      </div>
    </div>
  );

  const instaTable = (
    <table className="table">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="channels.account" />{" "}
          </th>
          <th>
            <FormattedMessage id="channels.autoReplies" />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {instagram.map((i) => (
          <tr key={i.id}>
            <td>{i.name}</td>

            <td>
              <span
                className={classNames("badge", {
                  "text-bg-success": i.active,
                  "text-bg-light": !i.active,
                })}
              >
                {i.active ? "auto" : "manual"}
              </span>
            </td>
            <td style={{ textAlign: "right" }}>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-custom-components"
                  as={CustomToggle}
                >
                  Custom toggle
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => toggleInsta(i)} eventKey="1">
                    {i.active ? (
                      <FormattedMessage id="common.pause" />
                    ) : (
                      <FormattedMessage id="common.activate" />
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const waTable = (
    <table className="table">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="channels.account" />{" "}
          </th>
          <th>
            <FormattedMessage id="channels.autoReplies" />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {whatsapp.map((w) => (
          <tr key={w.phoneNumberId}>
            <td>{w.phoneNumber || w.phoneNumberId}</td>
            <td>
              <span
                className={classNames("badge", {
                  "text-bg-success": w.active,
                  "text-bg-light": !w.active,
                })}
              >
                {w.active ? "auto" : "manual"}
              </span>
            </td>
            <td style={{ textAlign: "right" }}>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-custom-components"
                  as={CustomToggle}
                >
                  Custom toggle
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => toggleWa(w)} eventKey="1">
                    {w.active ? (
                      <FormattedMessage id="common.pause" />
                    ) : (
                      <FormattedMessage id="common.activate" />
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="settings-presentation">
      <h1 className="page-title">
        <FormattedMessage id="channels.title" />
      </h1>
      <div className="row">
        <div className="col-md-12">{FBMessenger}</div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="visito-card visito-block">
            <div className="visito-card-header">
              <div className="visito-card-title">
                <IconBrandInstagram width={22} style={{ marginTop: "-2px" }} />{" "}
                Instagram
              </div>
            </div>
            <div className="visito-card-body">
              {loading ? (
                <SkeletonLoader rows={1} />
              ) : instagram.length ? (
                instaTable
              ) : (
                empty
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="visito-card visito-block">
            <div className="visito-card-header">
              <div className="visito-card-title">
                <IconBrandWhatsapp width={22} style={{ marginTop: "-2px" }} />{" "}
                WhatsApp
              </div>
            </div>
            <div className="visito-card-body">
              {loading ? (
                <SkeletonLoader rows={1} />
              ) : whatsapp.length ? (
                waTable
              ) : (
                emptyWa
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({
  outbound: props.outboundEvents,
}))(Channels);
