import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}/integrations`;

const Cloudbeds = {
  cloudbedsCode(code) {
    const options = requestOptions("POST", { code }, true);
    const url = `${baseUrl}/cloudbeds`;
    return request(url, options);
  },
  cloudbedsPreviewCode(code) {
    const options = requestOptions("POST", { code });
    const url = `${baseUrl}/cloudbeds-preview`;
    return request(url, options);
  },
  cloudbedsOnboarding(code) {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/cloudbeds-onboarding`;
    return request(url, options);
  },
  getIntegrations() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/`;
    return request(url, options);
  },
};

export default Cloudbeds;
