import { useState, useEffect } from "react";

import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  tablePlugin,
  ListsToggle,
  InsertTable,
  BlockTypeSelect,
  linkPlugin,
  frontmatterPlugin,
} from "@mdxeditor/editor";

import "./TextEditor.css";

interface ILayoutProps {
  content: string;
  limit: number;
  onSave: Function;
  mode?: string;
  cancel?: Function;
  save?: Function;
}

const TextEditor: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { mode } = props;

  const [err, setErr] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [content, setContent] = useState<string>(props.content);
  const [saving, setSaving] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<number | null>(null);

  const cancel = () => {
    setEdit(false);
    props.cancel && props.cancel();
    console.log("cancel");
  };

  useEffect(() => {
    setContent(content);
  }, [props.content]);

  const Edit = () => {
    return (
      <div className="text-editor-extra">
        <div className="text-save">
          <div className="text-danger">
            <span>{err ? err : null} </span>
          </div>
        </div>
        <div className="action-buttons">
          <div style={{ marginRight: "10px" }}>
            {content.length}/{props.limit}{" "}
          </div>
          <div>
            {edit ? (
              <>
                <button
                  className="btn btn-light btn-sm"
                  onClick={() => cancel()}
                >
                  Cancel
                </button>{" "}
                {!err && (
                  <button
                    className="btn btn-visito-primary btn-sm"
                    onClick={() => saveContent()}
                    disabled={saving ? true : false}
                  >
                    {saving ? "Saving..." : "Save"}
                  </button>
                )}
              </>
            ) : (
              <button
                className="btn btn-visito-primary btn-sm"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Clean up the timeout on component unmount
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const saveContent = () => {
    setSaving(true);
    setErr("");
    props.onSave(content, (error: { err: string }) => {
      console.log(error);
      setErr(error.err);

      if (error.err) {
        setSaving(false);
      } else {
        setSaving(false);
        setEdit(false);
      }
    });
  };

  const handleContentChange = (newContent: string) => {
    const c = newContent.replace(/&#x20;$/, "");
    setContent(c);
    if (c.length > props.limit) {
      setErr(`Content exceeds the character limit`);
    } else {
      setErr("");
    }
  };

  const plugins = [
    headingsPlugin(),
    tablePlugin(),
    listsPlugin(),
    quotePlugin(),
    linkPlugin(),
    thematicBreakPlugin(),
    frontmatterPlugin(),
    markdownShortcutPlugin(),
  ];

  console.log(content);
  if (mode !== "preview") {
    plugins.unshift(
      toolbarPlugin({
        toolbarContents: () => (
          <>
            {mode === "mobile" ? null : (
              <>
                <BlockTypeSelect />
                <div id="styles">
                  <BoldItalicUnderlineToggles />
                </div>
                <div id="list">
                  <ListsToggle />
                </div>
                <InsertTable />
              </>
            )}
            <Edit />
          </>
        ),
      })
    );
  }

  return (
    <MDXEditor
      markdown={content}
      onChange={handleContentChange}
      readOnly={edit ? false : true}
      plugins={plugins}
    />
  );
};

const plugins = [
  headingsPlugin(),
  tablePlugin(),
  listsPlugin(),
  quotePlugin(),
  thematicBreakPlugin(),
  markdownShortcutPlugin(),
];

export const TextEditorV2 = ({
  content,
  limit,
  onChange,
}: {
  content: string;
  limit: number;
  onChange: (content: string) => void;
}) => {
  return (
    <MDXEditor
      markdown={content}
      onChange={onChange}
      readOnly={false}
      plugins={[
        headingsPlugin(),
        tablePlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        markdownShortcutPlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <BlockTypeSelect />
              <div id="styles">
                <BoldItalicUnderlineToggles />
              </div>
              <div id="list">
                <ListsToggle />
              </div>
              <InsertTable />
              <p className="mb-0 ml-auto">{`${content.length} / ${limit}`}</p>
            </>
          ),
        }),
      ]}
    />
  );
};

export default TextEditor;
