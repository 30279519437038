import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonModal,
  IonButton,
  IonButtons,
} from "@ionic/react";

import "./MediaModal.css";

const MediaModal: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { isOpen, img, setIsOpen } = props;

  function onWillDismiss() {
    setIsOpen(false);
  }

  return (
    <IonModal
      isOpen={isOpen}
      className="pictures-modal"
      onWillDismiss={() => onWillDismiss()}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <a href={img} download>
              {" "}
              Download
            </a>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="img-container-media ">
          <img style={{ maxHeight: "100%" }} src={img} alt="" />
        </div>
      </IonContent>
    </IonModal>
  );
};

interface ILayoutProps {
  isOpen: boolean;
  setIsOpen: Function;
  img: string;
}

export default MediaModal;
