import { createAction } from "@reduxjs/toolkit";
import { Request } from "../interfaces";

export const setRequest = createAction(
  "SET_REQUEST",
  function prepare(request: Request) {
    return {
      payload: { request },
    };
  },
);
