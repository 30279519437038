import { useEffect } from "react";
import { connect } from "react-redux";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonBackButton,
  IonButtons,
  IonList,
  IonItem,
  IonNote,
} from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";

import { STRIPE } from "../../../config";
//Inferfaces
import { User, Settings } from "../../../interfaces";

const Language: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { settings, user } = props;
  const intl = useIntl();
  const { payment, plan } = settings.status;

  useEffect(() => {
    props.onRequestSettings();
  }, []);

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/settings`}
              text={intl.formatMessage({ id: "common.back" })}
            />
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="payment.title" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <div className="view-wrapper">
          {!payment.active ? (
            <div className="view-pane mt-4">
              <>
                <stripe-pricing-table
                  pricing-table-id={STRIPE.pricingTable}
                  publishable-key={STRIPE.key}
                  client-reference-id={user.company.id}
                />
              </>
            </div>
          ) : (
            <>
              <div className="view-pane-title mt-4">
                <FormattedMessage id="payment.title" />
              </div>
              <IonList inset={true}>
                <IonItem>
                  <IonLabel>
                    <FormattedMessage id="payment.currentPlan" />
                  </IonLabel>

                  <div className="metadata-end-wrapper" slot="end">
                    <IonNote color="medium">{plan.name}</IonNote>
                  </div>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <FormattedMessage id="payment.billingEmail" />
                  </IonLabel>

                  <div className="metadata-end-wrapper" slot="end">
                    <IonNote color="medium">{payment.email}</IonNote>
                  </div>
                </IonItem>
                <IonItem button={true}>
                  <a
                    href={STRIPE.loginDashboard || ""}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      width: "100%",
                    }}
                  >
                    <FormattedMessage id="payment.managePlan" />
                  </a>
                </IonItem>
              </IonList>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  onRequestSettings: Function;
  user: User;
  settings: Settings;
}

export default connect((props: any) => ({}))(Language);
