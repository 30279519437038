import { useState } from "react";
import { connect } from "react-redux";
import { Location } from "history";
import { Link } from "react-router-dom";
import { IonPage, IonContent, IonText, useIonViewDidEnter } from "@ionic/react";

import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";

import styles from "./styles.module.css";
import "./login.css";

import Auth from "../../api/auth";
import { setAuth } from "../../utils/localStorage";
import { setAppUser, setAppAuth } from "../../actions/app";

interface RegisterForm {
  email: string;
  password: string;
  hotelName: string;
  website: string;
}

interface Error {
  message: string;
}

const Register: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [show, setShow] = useState(false);
  const [logInError, setLogInError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registerLink, setRegisterLInk] = useState("");
  const [formData, setFormData] = useState<RegisterForm>({
    email: "",
    password: "",
    hotelName: "",
    website: "",
  });

  useIonViewDidEnter(() => {
    const { search } = props.location;
    console.log(search);
    setRegisterLInk(`/auth/login${search}`);
  });

  const registerApi = async () => {
    await setLoading(true);
    const { data, err } = await Auth.register(formData);
    if (data) {
      const { token, refreshToken, user } = data;
      const { email, company, language } = user;
      await setAuth({ refreshToken, token, email, company });
      await props.dispatch(setAppAuth({ refreshToken, token }));
      await props.dispatch(setAppUser({ email, company, language }));
    }
    if (err) {
      let message = err.message;
      if (err.details && err.details.body && err.details.body.length) {
        err.details.body.forEach((e: Error) => {
          message = message + " " + e.message;
        });
      }
      await setLogInError(message);
    }
    await setLoading(false);
  };

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    registerApi();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className={styles.authContainer}>
          <div className={styles.authHolder}>
            <div className={styles.authPanel}>
              <h1>
                <FormattedMessage id="auth.register.title" />
              </h1>
              <form className={styles.authForm} onSubmit={handleForm}>
                <div className={styles.inputHolder}>
                  <label>
                    <FormattedMessage id="auth.register.company" />
                  </label>
                  <input
                    type="text"
                    id="hotelName"
                    placeholder="ACME Inc"
                    value={formData.hotelName}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    required
                  />
                </div>
                <div className={styles.inputHolder}>
                  <label>
                    <FormattedMessage id="auth.register.web" />
                  </label>
                  <input
                    placeholder="https://example.com"
                    type="url"
                    id="website"
                    value={formData.website}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    required
                  />
                </div>

                <div className={styles.inputHolder}>
                  <label>
                    <FormattedMessage id="auth.register.email" />
                  </label>
                  <input
                    placeholder="name@example.com"
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    required
                  />
                </div>
                <div
                  className={`${styles.inputHolder} ${styles.passwordHolder}`}
                >
                  <label>
                    <FormattedMessage id="auth.register.password" />
                  </label>
                  <input
                    type={show ? "text" : "password"}
                    placeholder="Password"
                    className="form-control"
                    id="password"
                    value={formData.password}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <span
                    onClick={() => setShow(!show)}
                    className={styles.togglePassword}
                  ></span>
                </div>

                <div className="color-step-500 fs-2 mt-3">
                  <FormattedMessage id="auth.register.continuing" />{" "}
                  <a
                    href="https://www.visitoai.com/en/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="auth.register.loginTerms" />
                  </a>{" "}
                  <FormattedMessage id="common.and" />{" "}
                  <a
                    href="https://www.visitoai.com/en/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="auth.register.loginPrivacy" />
                  </a>
                </div>

                {logInError && (
                  <p>
                    <IonText className="mb-1" color="danger">
                      {logInError}
                    </IonText>
                  </p>
                )}

                <button
                  type="submit"
                  className="btn btn-visito-primary w-100 py-8 mb- mt-4 rounded-2"
                >
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">
                        <FormattedMessage id="common.loading" />
                        ...
                      </span>
                    </Spinner>
                  ) : (
                    <FormattedMessage id="auth.register.register" />
                  )}
                </button>

                <div className="text-center mt-4">
                  <div className="color-step-500">
                    <FormattedMessage id="auth.register.loginLabel" />
                  </div>
                  <div>
                    <Link to={registerLink}>
                      <FormattedMessage id="auth.register.login" />
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({}))(Register);
