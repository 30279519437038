import { connect } from "react-redux";
import { useState } from "react";
import { Location } from "history";
import classNames from "classnames";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { FormattedMessage } from "react-intl";

import { IonPage, IonContent, useIonViewDidEnter, IonText } from "@ionic/react";

import { deserialize } from "../../../utils/serialize";
import { IconCheck, IconX } from "@tabler/icons-react";
import Api from "../../../api/integrations";

const Integrations: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  interface Integration {
    _id: string;
    name: string;
    accessToken: string;
  }

  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [code, setCode] = useState("");
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);

  const requestIntegrations = async () => {
    setLoadingList(true);
    const { data, err } = await Api.getIntegrations();
    if (data) {
      setIntegrations(data.integrations);
    }
    if (err) {
      console.log(err);
    }
    setLoadingList(false);
  };

  useIonViewDidEnter(() => {
    const { search } = props.location;
    const params = deserialize(search);
    const { code } = params;
    requestIntegrations();
    if (code) {
      setCode(code);
    }
  });

  const integrate = async (code: string) => {
    setLoading(true);
    const { data, err } = await Api.cloudbedsCode(code);
    setLoading(false);
    if (err) {
      return setErr(err.message);
    }
    if (data) {
      requestIntegrations();
      setSuccess(
        "You have succesfully accepted the integration with your cloudbeds account"
      );
    }
  };

  const table = (
    <table className="table">
      <thead>
        <tr>
          <th>Property</th>
          <th style={{ textAlign: "right" }}>Status</th>
        </tr>
      </thead>
      <tbody>
        {integrations.map((i) => (
          <tr key={i._id}>
            <td>{i.name}</td>
            <td style={{ textAlign: "right" }}>
              <span
                className={classNames("badge", {
                  "text-bg-success": i.accessToken,
                  "text-bg-light": !i.accessToken,
                })}
              >
                {i.accessToken ? (
                  <FormattedMessage id="common.active" />
                ) : (
                  <FormattedMessage id="common.inactive" />
                )}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const cloudbeds = (
    <div className="visito-card visito-block mb-4">
      <div className="visito-card-body bg-suttle">
        <h5>Cloudbeds</h5>
        <p>
          <FormattedMessage id="integrations.acceptCloudbedsNewIntegration" />
        </p>
        {!err && !success ? (
          <button
            className="btn btn-visito-primary"
            color="success"
            onClick={() => integrate(code)}
            disabled={loading}
          >
            {loading ? (
              <FormattedMessage id="common.loading" />
            ) : (
              <FormattedMessage id="common.accept" />
            )}
          </button>
        ) : (
          <>
            {err && (
              <IonText color="danger">
                <IconX width={18} /> {err}
              </IonText>
            )}
            {success && (
              <IonText color="success">
                <IconCheck width={18} /> {success}
              </IonText>
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper container">
          <h1 className="page-title">
            <FormattedMessage id="integrations.title" />
          </h1>
          <div className="row">
            <div className="col-md-6">
              {code && cloudbeds}
              <div className="visito-card visito-block">
                <div className="visito-card-header">
                  <div className="visito-card-title">
                    <FormattedMessage id="integrations.propertiesListTitle" />
                  </div>
                </div>
                <div className="visito-card-body">
                  {loadingList ? (
                    <SkeletonLoader rows={1} />
                  ) : integrations.length ? (
                    table
                  ) : (
                    <FormattedMessage id="integrations.propertiesListTitle" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({
  outbound: props.outboundEvents,
}))(Integrations);
