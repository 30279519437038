import { createAction } from "@reduxjs/toolkit";

export const setSendLoading = createAction("SEND_SET_LOADING", function prepare(
  loading: boolean
) {
  return {
    payload: loading
  };
});

export const setSendBody = createAction("SEND_SET_BODY", function prepare(
  body: String
) {
  return {
    payload: { body }
  };
});

export const cleanUpSendBody = createAction("CLEAN_UP_SEND_BODY", function prepare() {
  return {
    payload: { clean: true }
  };
});



/*
interface apiResult { [key: string]: any };

export const setContactsLoading = createAction("CONTACTS_SET_LOADING", function prepare(
  loading: boolean
) {
  return {
    payload: loading
  };
});

export const setContacts = createAction("CONTACTS_SET_CONTACTS", function prepare(
  contacts: apiResult[]
) {
  return {
    payload: { contacts }
  };
});
*/