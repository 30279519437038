import { useState } from "react";
import { Location } from "history";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import serialize, { deserialize } from "../../utils/serialize";
import { IonPage, IonContent, IonText, useIonViewDidEnter } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import Spinner from "react-bootstrap/Spinner";

import styles from "./styles.module.css";
//import "./login.css";

import Auth from "../../api/auth";
import { setAuth } from "../../utils/localStorage";
import { setAppUser, setAppAuth } from "../../actions/app";

interface LoginForm {
  email: string;
  password: string;
}

const Login: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [logInError, setLogInError] = useState(null);
  const [show, setShow] = useState(false);
  const [registerLink, setRegisterLInk] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<LoginForm>({
    email: "",
    password: "",
  });

  useIonViewDidEnter(() => {
    const { search } = props.location;
    const queryObject = deserialize(search);

    if (queryObject.code && queryObject.pathname === "/cloudbeds") {
      const queryString = serialize(queryObject);
      setRegisterLInk(`/auth/register-cloudbeds?pathname=/&${queryString}`);
    } else {
      setRegisterLInk(`/auth/register${search}`);
    }
  });

  const loginApi = async () => {
    await setLoading(true);
    const { data, err } = await Auth.login(formData);
    if (data) {
      const { token, refreshToken, user } = data;
      const { email, company, language } = user;
      await setAuth({ refreshToken, token, email, company });
      await props.dispatch(setAppAuth({ refreshToken, token }));
      await props.dispatch(setAppUser({ email, company, language }));
    }
    if (err) {
      await setLogInError(err.message);
    }
    await setLoading(false);
  };

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginApi();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className={styles.authContainer}>
          <div className={styles.authHolder}>
            <div className={styles.authPanel}>
              <h1>
                {" "}
                <FormattedMessage id="auth.login.title" />
              </h1>
              <form className={styles.authForm} onSubmit={handleForm}>
                <div className={styles.inputHolder}>
                  <label className="form-label">
                    <FormattedMessage id="auth.login.email" />
                  </label>
                  <input
                    type="email"
                    required
                    placeholder="name@example.com"
                    id="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                  />
                </div>
                <div
                  className={`${styles.inputHolder} ${styles.passwordHolder}`}
                >
                  <label className="form-label">
                    <FormattedMessage id="auth.login.password" />
                  </label>
                  <input
                    type={show ? "text" : "password"}
                    required
                    placeholder="Password"
                    className="form-control"
                    id="password"
                    value={formData.password}
                    onChange={(e) => handleChange(e)}
                  />
                  <span
                    onClick={() => setShow(!show)}
                    className={styles.togglePassword}
                  ></span>
                </div>

                {logInError && (
                  <p>
                    <IonText className="mb-1" color="danger">
                      {logInError}
                    </IonText>
                  </p>
                )}
                <button
                  type="submit"
                  className="btn btn-visito-primary w-100 py-8 mb-4 mt-4 rounded-2"
                >
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">
                        <FormattedMessage id="common.loading" />
                        ...
                      </span>
                    </Spinner>
                  ) : (
                    <FormattedMessage id="auth.login.login" />
                  )}
                </button>

                <div className="text-center">
                  <div className="color-step-500">
                    <FormattedMessage id="auth.login.forgotLabel" />{" "}
                    <Link to="/auth/forgot-password">
                      <FormattedMessage id="auth.login.forgotPassword" />
                    </Link>
                  </div>
                </div>

                <div className="text-center mt-4">
                  <div className="color-step-500">
                    <FormattedMessage id="auth.login.registerLabel" />
                  </div>
                  <div>
                    <Link to={registerLink}>
                      <FormattedMessage id="auth.login.register" />
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({}))(Login);
