import { combineReducers } from "@reduxjs/toolkit";
import { App } from './app';
import { Contacts } from './contacts';
import { Send } from './send';
import { Events } from './events';
import { Requests } from './requests';

const rootReducer = combineReducers({
  app: App,
  contacts: Contacts,
  send: Send,
  events: Events,
  requests: Requests,
});


export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
