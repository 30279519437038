import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useIonToast } from "@ionic/react";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  tablePlugin,
  ListsToggle,
  InsertTable,
  linkPlugin,
  frontmatterPlugin,
  BlockTypeSelect,
} from "@mdxeditor/editor";

import AiApi from "../../../api/ai";

import styles from "./styles.module.css";
import useDebounce from "../../../hooks";

type AiContext = {
  limit: number;
  text: string;
};

type AiContextProps = AiContext;

function AiTextContext({ limit, text }: AiContextProps) {
  const [context, setContext] = React.useState<AiContext>({
    limit: limit,
    text: text,
  });

  const [present] = useIonToast();

  const intl = useIntl();

  const queryClient = useQueryClient();

  const { mutate: mutateUpdateSettings, isPending: isUpdatingSettings } =
    useMutation({
      mutationFn: AiApi.putAiSettingsByFieldV2,
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      },
    });

  const onSave = useDebounce((value: string) => {
    if (value.length > context.limit) {
      return present({
        message: intl.formatMessage({ id: "aiExceededLength" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    }
    mutateUpdateSettings({ field: "general", value });
  }, 800);

  const onChangeEditor = (content: string) => {
    setContext((prevState) => ({ ...prevState, text: content }));

    onSave(content);
  };

  React.useEffect(() => {
    return () => {
      queryClient.invalidateQueries({
        queryKey: ["getAiSettings"],
      });
    };
  }, []);

  return (
    <div className={styles.editorContainer}>
      <MDXEditor
        markdown={context.text}
        onChange={onChangeEditor}
        readOnly={false}
        contentEditableClassName={styles.editorFixedHeight}
        plugins={[
          headingsPlugin(),
          tablePlugin(),
          listsPlugin(),
          quotePlugin(),
          linkPlugin(),
          thematicBreakPlugin(),
          frontmatterPlugin(),
          markdownShortcutPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <BlockTypeSelect />
                <div id="styles">
                  <BoldItalicUnderlineToggles />
                </div>
                <div id="list">
                  <ListsToggle />
                </div>
                <InsertTable />
                <div className="ml-auto flex gap-3 align-items-center">
                  <p className="mb-0">
                    {`${context.text.length} / ${context.limit}`}
                  </p>
                  {isUpdatingSettings && (
                    <p className="mb-0 fw-semibold">
                      <FormattedMessage id="aiSettingsSaving" />
                    </p>
                  )}
                </div>
              </>
            ),
          }),
        ]}
      />
    </div>
  );
}

export default AiTextContext;
