import { createAction } from "@reduxjs/toolkit";
import { Event, Socket } from '../interfaces';


export const setEvent = createAction("SET_EVENT", function prepare(
  event: Event
) {
  return {
    payload: { event }
  };
});


export const setSocket = createAction("SET_SOCKET", function prepare(
  socket: Socket
) {
  return {
    payload: { socket }
  };
});

export const setLoaded = createAction("SET_SOCKET_LOADED", function prepare() {
  return {
    payload: false
  };
});
