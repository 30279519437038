import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonModal,
  IonButton,
  IonButtons,
} from "@ionic/react";

import { IconFileCheck } from "@tabler/icons-react";

import "./UploadPhotoModal.css";

const UploadPhotoModal: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { show, handleClose, files, upload, mode, areDocs = false } = props;
  const [caption, setCaption] = useState("");

  const onWillDismiss = () => handleClose(false);

  const urls = files.map((file) => URL.createObjectURL(file));

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setCaption(value);
  };

  const onSubmit = () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("caption", caption);
    formData.append("fileType", areDocs ? "document" : "image")
    return upload(formData);
  };

  const content = (
    <div className="upload-photo-modal">
      {" "}
      <div className="text-center img-holder">
        {urls.map((url, i) => {
          const filename = files[i].name;
          return (
            <div key={i}>
              {!areDocs ? (
                <>
                  <img src={url} alt={filename} />
                  <p className="mb-2">{filename}</p>
                </>
              ) : (
                <>
                  <p className="flex align-items-center gap-2 mb-2">
                    <IconFileCheck />
                    {filename}
                  </p>
                </>
              )}
            </div>
          );
        })}
      </div>
      <div className="mt-2 input-holder">
        <input
          type="text"
          placeholder="Add a caption..."
          className="form-control"
          id="caption"
          value={caption}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </div>
  );

  return mode === "desktop" ? (
    <Modal
      show={show}
      onHide={onWillDismiss}
      onShow={() => setCaption("")}
      centered
      data-bs-theme="dark"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-visito-primary btn-sm" onClick={onSubmit}>
          Send
        </button>
      </Modal.Footer>
    </Modal>
  ) : (
    <IonModal isOpen={show} onWillDismiss={onWillDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              className="color-visito-2"
              onClick={() => onWillDismiss()}
            >
              Close
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              className="color-visito-2"
              onClick={() => onSubmit()}
            >
              Send
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">{content}</IonContent>
    </IonModal>
  );
};

interface ILayoutProps {
  show: boolean;
  mode: string;
  handleClose: Function;
  files: File[];
  upload: Function;
  areDocs?: boolean;
}

export default UploadPhotoModal;
