import config from '../config';
import request, { requestOptions } from '../utils/request';

const baseUrl = `${config.API_ROOT}/app/playground`;

type CreateWebIdRes = {
  err?: boolean;
  data?: {
    ok: boolean;
    playground: {
      contactId: string;
    };
  }
}

const Playground = {
  async createWebId({ webId, name }: { webId: string; name: string }) {
    const options = requestOptions('POST', { webId, name }, true);
    const res = await request(baseUrl, options);
    return res as CreateWebIdRes;
  },
  async sendMessage({ contactId, content }: { contactId: string, content: string }) {
    const options = requestOptions('POST', { contactId, content }, true);
    const url = `${baseUrl}/message`;
    const { data } = await request(url, options);
    return data as { ok: boolean };
  },
  async clearPlayground({ contactId }: { contactId: string }) {
    const options = requestOptions('POST', { contactId }, true);
    const url = `${baseUrl}/clear`;
    const { data } = await request(url, options);
    return data as { ok: boolean };
  },
  async getCompanyInfo({ slug }: { slug: string }) {
    const options = requestOptions('GET', {}, true);
    const url = `${baseUrl}/company/${slug}`;
    const { data } = await request(url, options);
    return data as { ok: boolean, company?: { _id: string, name: string } };
  },
  async createFreeContact({ webId, companyId, name }: { webId: string; companyId: string; name: string }) {
    const options = requestOptions('POST', { webId, companyId, name }, true);
    const url = `${baseUrl}/free-contact`;
    const res = await request(url, options);
    return res as CreateWebIdRes;
  },
  async sendFreeMessage({ contactId, content, companyId }: { contactId: string, content: string, companyId: string }) {
    const options = requestOptions('POST', { contactId, content, companyId }, true);
    const url = `${baseUrl}/free-message`;
    const { data } = await request(url, options);
    return data as { ok: boolean };
  },
  async clearFreePlayground({ contactId, companyId }: { contactId: string; companyId: string }) {
    const options = requestOptions('POST', { contactId, companyId }, true);
    const url = `${baseUrl}/clear-free-conversation`;
    const { data } = await request(url, options);
    return data as { ok: boolean };
  },
}

export default Playground;
