import config from '../config';
import { setAuth, removeAuth, getAuth } from './localStorage';
const baseUrl = `${config.API_ROOT}/auth`;

function apiRefreshToken(funcs, payload) {
  const options = funcs.requestOptions('POST', payload, false);
  const url = `${baseUrl}/refresh-token/`;
  return funcs.request(url, options);
}

function sendRequest(funcs, token, url, options) {
  options.headers.set('Authorization', `bearer ${token}`);
  return funcs.request(url, options);
}

function refreshTokenLogics(funcs, payload) {
  return new Promise((resolve, reject) => {
    apiRefreshToken(funcs, payload)
      .then((result) => {
        const { err, data } = result;
        if (err) {
          return reject(err);
        }
        if (data) {
          console.log(data);
          const { newRefreshToken, token, user } = data;
          setAuth({
            refreshToken: newRefreshToken, token, email: user.email,
          }).then((data) => resolve(data));
        }
      });
  });
}

export default function interceptRefreshToken(funcs, url, options) {

  console.log('working');
  return getAuth()
    .then((auth) => {
      const { email, refreshToken } = auth;
      const payload = { email, refreshToken };
      return refreshTokenLogics(funcs, payload)
        .then(data => {
          console.log('refreshed token sucess....');
          return sendRequest(funcs, data.token, url, options);
        })
        .catch(err => {
          console.log(err)
          const { statusCode, error } = err;
          if (statusCode === 404 && error === 'Token') {
            return removeAuth().then(() => {
              window.location.href = "/";
            });
          }
          if (statusCode === 400) {
            return removeAuth().then(() => {
              window.location.href = "/";
            });
          }
        });
    });
}
