import { IonSkeletonText } from "@ionic/react";

const SkeletonText: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { rows } = props;
  const el = Array.from({ length: rows }, (_, index) => index);

  return (
    <>
      {el.map((item, index) => (
        <IonSkeletonText
          key={index}
          animated={true}
          style={{
            marginBottom: "25px",
            width: `${Math.floor(Math.random() * 51 + 50)}%`,
          }}
        />
      ))}
    </>
  );
};

interface ILayoutProps {
  rows: number;
}

export default SkeletonText;
