import config from '../config';
import { OutboundEvent, OutboundEventLog, OutboundEventStats, Pagination, Location } from '../interfaces';
import adaptOutboundLogs, { adaptOutboundEvents, adaptStats } from '../utils/outboundLogs';
import request, { requestOptions } from '../utils/request';

const baseUrl = `${config.API_ROOT}/app/outbound-events-locations`;

const OutboundEvents = {
  async getOutboundEvents({ location }: { location?: string }) {
    const options = requestOptions('GET', {}, true);
    const url = new URL(`${baseUrl}`);
    if (location) {
      url.searchParams.set("location", location)
    }
    const { data } = await request(url.toString(), options);
    const { outbound_events, locations } = data;

    if (!data || !outbound_events) {
      throw new Error("There was an error getting the outbound events");
    }

    const adaptedData = adaptOutboundEvents(outbound_events) as OutboundEvent[];

    return { events: adaptedData, locations: locations as Location[] };
  },
  async getOutboundEventsLogs(
    { id, date = null, limit = null, page = null } : 
    { id: string, date?: null | string, limit?: null | number, page?: null | number })
  {
    const options = requestOptions('GET', {}, true);
    const url = new URL(`${baseUrl}/${id}`);
    if (date) {
      url.searchParams.set("date", date)
    }
    if (limit) {
      url.searchParams.set("limit", String(limit))
    }
    if (page) {
      url.searchParams.set("page", String(page))
    }

    const { data } = await request(url.toString(), options);
    const { outbound_event, outbound_event_logs, pagination } = data;

    if (!data || !outbound_event || !outbound_event_logs) {
      throw new Error("There was an error getting the logs");
    }

    const adaptedData: { logs: OutboundEventLog[]; outboundEvent: OutboundEvent } =
      adaptOutboundLogs(outbound_event_logs, outbound_event);
    
    return { ...adaptedData, pagination: pagination as Pagination }
  },
  async getOutboundEventsStats({ location }: { location?: string }) {
    const options = requestOptions('GET', {}, true);
    const url = new URL(`${baseUrl}/stats`);

    if (location) {
      url.searchParams.set("location", location)
    }

    const { data } = await request(url.toString(), options);
    const { stats } = data;

    if (!data || !stats) {
      throw new Error("There was an error getting the logs");
    }

    const adaptedData = adaptStats(stats);
    return adaptedData as OutboundEventStats[];
  },
  putOutboundEvent({ id, active }: { id: string, active: boolean }) {
    const options = requestOptions('PUT', { active }, true);
    const url = `${baseUrl}/${id}`;
    return request(url, options);
  }
}

export default OutboundEvents;