import React, { useState, useEffect, useRef } from "react";

import FilterSvg from "../../../theme/images/filter.svg";
import { FormattedMessage } from "react-intl";

// const CheckboxGroup = Checkbox.Group;
import styles from "./styles.module.css";

interface State {
  dropdownStatus: boolean;
}

interface Option {
  id: string;
}

const DropdownCheckbox: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [state, setState] = useState<State>({
    dropdownStatus: false,
  });
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setState((prevState) => ({ ...prevState, dropdownStatus: false }));
    }
  };

  const onToggle = () => {
    setState({ ...state, dropdownStatus: !state.dropdownStatus });
  };

  return (
    <div ref={dropdownRef}>
      <div className={styles.btnNavigation}>
        <button
          className={`btn btn-xs btn-ionic-light btn-flex ${styles.btnHeader}`}
          onClick={() => onToggle()}
        >
          <div className={styles.btnImageFilter}>
            <img src={FilterSvg} />
          </div>
          <div>
            <div className="d-flex">
              <FormattedMessage id={`chats.filter`} />
              {props.filters.length ? (
                <div style={{ marginLeft: "3px" }}>
                  <div className="badge color-visito-1-bg">
                    {props.filters.length}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </button>{" "}
      </div>

      <div
        className={styles.dropdownContainer}
        style={{ display: state.dropdownStatus ? "inherit" : "none" }}
      >
        <div className={styles.dropdownBody}>
          {props.options.map((o: { id: string }) => (
            <div className="form-check" key={o.id}>
              <input
                className="form-check-input primary"
                type="checkbox"
                onChange={(e) =>
                  props.onFilterChange({
                    field: e.target.id,
                    checked: e.target.checked,
                  })
                }
                id={o.id}
                checked={props.filters.includes(o.id)}
              />
              <label
                className="form-check-label fs-2 fw-semibold-1 pointer"
                htmlFor={o.id}
              >
                <FormattedMessage id={`chats.${o.id}`} />
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
interface ILayoutProps {
  options: Option[];
  onFilterChange: Function;
  filters: string[];
}
export default DropdownCheckbox;
