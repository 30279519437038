import React from "react";
import useScript from "react-script-hook";
import { apiResult } from "../interfaces";
export const FacebookSdk: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { onLoginSucces, className, onCodeSucces, text, config } = props;

  const handleFBload = () => {
    window.FB.init({
      appId: "516908693723488",
      cookie: true,
      xfbml: true,
      version: "v19.0",
    });

    /*

    window.FB.Event.subscribe(
      "auth.authResponseChange",
      (response: apiResult) => {
        console.log("auth.authResponseChange");
        console.log(response);
        if (response.status === "connected") {
          success(response.authResponse);
        }
      },
    );
    */
  };

  const success = (auth: AuthResponse) => {
    const { code, accessToken } = auth;
    const { type, platform } = config;

    const token = type === "user" ? accessToken : code;

    if (token) {
      onCodeSucces({
        token,
        type,
        platform,
      });
    }
  };

  /*
  const getLoginStatus = () => {
    console.log("calling getLoginStatus");
    window.FB.getAuthResponse((response: apiResult) => {
      console.log(response, "getLoginStatus");
    });
  };
  */

  const handleLogin = () => {
    window.FB.login(
      (response: apiResult) => {
        console.log(response);
        if (
          response.status === "connected" ||
          (config.type === "system" && response.status === "unknown")
        ) {
          onLoginSucces();
          console.log("getting token after login", response.authResponse);
          success(response.authResponse);
        }
      },
      {
        config_id: config.id,
        response_type: config.type === "user" ? "token" : "code",
        override_default_response_type: true,
      }
    );
  };

  /*
  const handleLogout = () => {
    window.FB.logout((response: apiResult) => {
      console.log(response);
    });
  };
  */

  useScript({
    src: "https://connect.facebook.net/en_US/sdk.js",
    onload: () => handleFBload(),
  });

  return (
    <div>
      <button className={className} onClick={() => handleLogin()}>
        {text || "Connect FB Messenger"}
      </button>
    </div>
  );
};

interface ILayoutProps {
  onLoginSucces: Function;
  onCodeSucces: Function;
  className: string;
  config: {
    id: string;
    type: string;
    platform: string;
  };
  text?: string;
}

interface AuthResponse {
  accessToken?: string;
  code?: string;
}

export default FacebookSdk;
