import { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
} from "@ionic/react";

import SkeletonText from "../../../components/SkeletonText";
import TextEditor from "../../../components/TextEditor";

// Api
import AiApi from "../../../api/ai";

// CSS
import "@mdxeditor/editor/style.css";

interface AiSetting {
  limit: number;
  text: string;
}

const AiSettings: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");

  const [ai, setAi] = useState<AiSetting>({
    limit: 0,
    text: "",
  });

  useEffect(() => {
    setType(props.match.params.type);
  }, [props.match.params.type]);

  useEffect(() => {
    if (type) {
      requestAiSettings(type);
    }
  }, [type]);

  const setAiValues = (data: any, type: string) => {
    if (data) {
      switch (type) {
        case "general":
          setAi({ ...data.aiSettings.context.general });
          break;
        case "customer":
          setAi({ ...data.aiSettings.context.customer });
          break;
      }
    }
  };

  const requestAiSettings = async (type: string) => {
    setLoading(true);
    const { data, err } = await AiApi.getAiSettings();
    if (err) {
      console.log(err);
    }
    if (data) {
      setAiValues(data, type);
    }
    setLoading(false);
  };
  const updateContext = async (content: string, cb: Function) => {
    const { err } = await AiApi.putAiSettingsByField(type, content);
    cb({ err });
  };

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/ai-settings">
              {" "}
              AI Settings
            </IonBackButton>
          </IonButtons>
          <IonTitle>Text</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div>
          {loading ? (
            <SkeletonText rows={17} />
          ) : (
            <TextEditor
              limit={ai.limit}
              content={ai.text}
              onSave={updateContext}
              mode={"mobile"}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps
  extends RouteComponentProps<{
    type: string;
  }> {}

export default AiSettings;
