import {
  getTodayForFilter,
  getCurrentWeek,
  getLastDays,
  getCurrentMonth,
  getLastMonth,
} from "../../../../utils/moment";

const COMMON_FILTERS = [
  { value: getTodayForFilter(), label: "common.today" },
  { value: getCurrentWeek(), label: "common.week" },
  { value: getLastDays({ days: 7 }), label: "common.7days" },
  { value: getCurrentMonth(), label: "common.thisMonth" },
  { value: getLastMonth(), label: "common.lastMonth" },
  { value: "custom", label: "common.custom" },
];

export default COMMON_FILTERS;
