import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { hotjar } from 'react-hotjar';
import { useLocation } from "react-router-dom";

import config from "../config";

if (config.HOTJAR_ID) {
  hotjar.initialize({ id: Number(config.HOTJAR_ID), sv: 6 });
}

export const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // @ts-ignore
    if (window.GA_INITIALIZED) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: "Visito App",
      });
    }
  }, [location.pathname]);

  React.useEffect(() => {
    // @ts-ignore
    if (!window.GA_INITIALIZED && config.GA_ID) {
      ReactGA.initialize(config.GA_ID);
      // @ts-ignore
      window.GA_INITIALIZED = true;
    }
  }, []);

  return <></>;
};

export default GoogleAnalytics;
