import { createAction } from "@reduxjs/toolkit";

interface apiResult {
  [key: string]: any;
}

export const setContactsLoading = createAction(
  "CONTACTS_SET_LOADING",
  function prepare(loading: boolean) {
    return {
      payload: loading,
    };
  },
);

export const setContacts = createAction(
  "CONTACTS_SET_CONTACTS",
  function prepare(contacts: apiResult[]) {
    return {
      payload: { contacts },
    };
  },
);

export const setInitialContactsDb = createAction(
  "CONTACTS_INITIAL_SET_CONTACTS",
  function prepare(contacts: apiResult[]) {
    return {
      payload: { contacts },
    };
  },
);

export const setSelectedMessages = createAction(
  "CONTACTS_SET_SELECTED_MESSAGES",
  function prepare(contactId: string) {
    return {
      payload: { contactId },
    };
  },
);

export const insertSentMessage = createAction(
  "CONTACTS_INSERT_SENT_MESSAGES",
  function prepare(contactId: string, content: string) {
    return {
      payload: { contactId, content },
    };
  },
);

export const modifyEnabled = createAction(
  "CONTACTS_MODIFY_ENABLED",
  function prepare(contactId: string, enabled: boolean) {
    return {
      payload: { contactId, enabled },
    };
  },
);

export const readMessages = createAction(
  "CONTACTS_READ_MESSAGES",
  function prepare(contactId: string) {
    return {
      payload: { contactId },
    };
  },
);

export const changeContactStatus = createAction(
  "CONTACTS_CHANGE_STATUS",
  function prepare(contactId: string, status: string) {
    return {
      payload: { contactId, status },
    };
  },
);

export const updateContactsDb = createAction(
  "CONTACTS_UPDATE_CONTACTS",
  function prepare(contacts: apiResult[]) {
    return {
      payload: { contacts },
    };
  },
);
