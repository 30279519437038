import { createReducer } from "@reduxjs/toolkit";
import {
  getTimeStampForContact,
  getTimeStampForMessage,
  getTimeStampForMessageGrouping,
} from "../utils/moment";
import { AsYouType } from "libphonenumber-js";
import getSvgs from "../utils/messageSvgs";
import {
  setContactsLoading,
  insertSentMessage,
  modifyEnabled,
  changeContactStatus,
  updateContactsDb,
  readMessages,
} from "../actions/contacts";

import {
  ContactsDBItem,
  Contact,
  apiResult,
  messageApi,
  ContactsDBObject,
  Escalation,
} from "../interfaces";

const loading = true;
const contactsDb: ContactsDBItem[] = [];
const contactList: Contact[] = [];
const contactsDbObject: ContactsDBObject = {};
const escalatedCount = 0;

function howMany(messages: messageApi[], roles: Array<any>) {
  let lastTime = null;
  let lastMessage = null;
  let unread = 0;

  const count = messages.reduce((count, message) => {
    if (!message.isRead) {
      unread++;
    }
    if (roles.includes(message.role)) {
      count++;
    }
    lastTime = message.createAt;
    lastMessage = message.content;
    return count;
  }, 0);

  return {
    count,
    lastTime,
    lastMessage: lastMessage ? lastMessage : "",
    unread,
  };
}

function runChatList(contacts: ContactsDBObject) {
  let list = [];
  let escalated = 0;
  let manual = 0;
  for (const key in contacts) {
    if (contacts.hasOwnProperty(key)) {
      const value: ContactsDBItem = contacts[key];
      if (value.contact.count) {
        list.push(value.contact);
      }
      if (value.contact.escalated) {
        escalated++;
      }
      if (value.contact.manual) {
        manual++;
      }
    }
  }
  return {
    list: list.sort((a, b) => b.timestampNative - a.timestampNative),
    escalated,
    manual,
  };
}

const getContactType = (contact: apiResult) => {
  if (contact.igId) {
    return "ig";
  }
  if (contact.fmId) {
    return "fm";
  }
  if (contact.calryId) {
    return "ab";
  }
  if (contact.phoneNumber || contact.phoneNumberId) {
    return "wa";
  }
  if (contact.webId) {
    return "web";
  }
  if (contact.callId) {
    return "voice";
  }
  return "play";
};

function adaptItem(c: apiResult, properties: apiResult) {
  return {
    contact: {
      name: c.fullName,
      timestamp: getTimeStampForContact(properties.lastTime),
      timestampNative: new Date(properties.lastTime).getTime(),
      preview: properties.lastMessage,
      id: c._id,
      unread: properties.unread,
      enabled: c.enabled,
      manual: !c.enabled,
      optedOut: c.optedOut,
      phoneNumber: new AsYouType().input(`+${c.phoneNumber}`),
      status: c.status,
      escalated: c.escalations.some((e: Escalation) => e.open),
      count: properties.count,
      type: getContactType(c),
      followers: c.meta ? c.meta.followerCount : 0,
      username: c.meta ? c.meta.username : "",
    },
    messages: c.messages.map((m: apiResult) => ({
      id: m._id,
      content: m.content,
      right: m.role === "assistant" || m.role === "outbound",
      status: m.status,
      escalated: m.escalated || false,
      timestamp: getTimeStampForMessage(m.createAt),
      timestampGrouping: getTimeStampForMessageGrouping(m.createAt),
      svg: getSvgs(m.status),
      interactive: {
        flag: m.interactive ? true : false,
        data: m.interactive,
      },
      isManual: m.meta ? true : false,
    })),
    bookings: c.bookings.map((b: apiResult) => ({
      phoneNumber: b.phoneNumber,
      status: b.status,
      externalId: b.externalId,
      fullName: b.fullName,
      from: b.from,
      to: b.to,
      locationName: b.locationName,
    })),
    escalations: c.escalations.map((e: apiResult) => ({
      type: e.type,
      open: e.open,
      details: e.details,
    })),
  };
}

export const Contacts = createReducer(
  { contactsDb, contactsDbObject, contactList, loading, escalatedCount },
  {
    [setContactsLoading.type]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    [updateContactsDb.type]: (state, action) => {
      console.log("received", action.payload.contacts.length);
      action.payload.contacts.forEach((c: apiResult) => {
        const properties = howMany(c.messages, ["user", "assistant"]);
        const contact = adaptItem(c, properties);
        state.contactsDbObject[contact.contact.id] = contact;
      });
      const { list, escalated } = runChatList(state.contactsDbObject);
      const filteredList = list.filter((contact) => contact.type !== "play");
      state.contactList = filteredList;
      state.escalatedCount = escalated;
    },
    [insertSentMessage.type]: (state, action) => {
      const currentTimestampNative = new Date().getTime();
      const currentMessageTimestamp = getTimeStampForMessage(new Date());
      const currentGroupingTimestamp = getTimeStampForMessageGrouping(
        new Date()
      );

      const index = state.contactsDb.findIndex(
        (s) => s.contact.id === action.payload.contactId
      );
      if (index !== -1) {
        const currentContact = state.contactsDb[index];

        // Update contact details
        currentContact.contact.timestamp = getTimeStampForContact();
        currentContact.contact.timestampNative = currentTimestampNative;
        currentContact.contact.preview = action.payload.content;

        // Add the new message
        const newMessage = {
          content: action.payload.content,
          right: true,
          status: "unsent",
          escalated: false,
          timestamp: currentMessageTimestamp,
          timestampNative: currentTimestampNative,
          timestampGrouping: currentGroupingTimestamp,
          svg: getSvgs("unsent"),
          interactive: {
            flag: false,
          },
          isManual: true,
        };
        currentContact.messages.push(newMessage);
        currentContact.messages.sort(
          (a, b) => b.timestampNative - a.timestampNative
        );
      }
      //state.contactList = runChatList(state.contactsDb);
    },
    [modifyEnabled.type]: (state, action) => {
      const index = state.contactsDb.findIndex(
        (s) => s.contact.id === action.payload.contactId
      );
      if (index !== -1) {
        state.contactsDb[index] = {
          ...state.contactsDb[index],
          contact: {
            ...state.contactsDb[index].contact,
            enabled: action.payload.enabled,
          },
        };
      }
    },
    [changeContactStatus.type]: (state, action) => {
      const index = state.contactsDb.findIndex(
        (s) => s.contact.id === action.payload.contactId
      );
      if (index !== -1) {
        state.contactsDb[index] = {
          ...state.contactsDb[index],
          contact: {
            ...state.contactsDb[index].contact,
            status: action.payload.status,
          },
        };
        //state.contactList = runChatList(state.contactsDb);
      }
    },
    [readMessages.type]: (state, action) => {
      const index = state.contactsDb.findIndex(
        (s) => s.contact.id === action.payload.contactId
      );
      if (index !== -1) {
        state.contactsDb[index] = {
          ...state.contactsDb[index],
          contact: {
            ...state.contactsDb[index].contact,
            unread: 0,
          },
        };
      }
    },
  }
);
