import { connect } from "react-redux";
import {
  IonHeader,
  IonToolbar,
  isPlatform,
  IonPage,
  IonContent,
  IonTitle,
} from "@ionic/react";

// Reac int
import { FormattedMessage } from "react-intl";

// Types
import { User, Settings } from "../../interfaces";

// Components
import Onboarding from "./onboarding";
import Stats from "./stats";

// Styles
import headerStyles from "../../theme/header.module.css";
import styles from "./styles.module.css";

// Actions
import { setOnboarded } from "../../actions/app";

// Api
import AiApi from "../../api/ai";

// Other
const isMobile = isPlatform("android") || isPlatform("ios");

interface HeaderProps {
  name: string;
}

const Header: React.FC<HeaderProps> = ({ name }) => {
  return (
    <div>
      <h1 className={headerStyles.pageTitle}>
        <FormattedMessage id="home.pageTitle" /> {name} 👋
      </h1>
      <h3 className={headerStyles.pageDescription}>
        <FormattedMessage id="home.pageDescription" />
      </h3>
    </div>
  );
};

const MobileHeader: React.FC<HeaderProps> = ({ name }) => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="home.pageTitle" /> {name} 👋
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="home.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const MobileTitle = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          <FormattedMessage id="nav.home" />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

const Home: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { user, settings } = props;

  // API
  const handleSkip = async () => {
    const { data } = await AiApi.putAiSettingsByField("onboarded", true);

    if (data) {
      await props.dispatch(setOnboarded(true));
    }
  };

  return (
    <IonPage id="main-content">
      {isMobile && <MobileTitle />}
      <IonContent
        {...(isMobile
          ? { fullscreen: true, color: "light", className: "ion-padding" }
          : {})}
      >
        <div className={isMobile ? "view-wrapper" : "page-wrapper container"}>
          {isMobile ? (
            <>
              <div className="view-pane">
                <MobileHeader name={user.email} />
              </div>
              {settings.onboarded ? null : (
                <Onboarding
                  settings={settings}
                  isMobile={isMobile}
                  onSkip={handleSkip}
                />
              )}
              <Stats isMobile={isMobile} />
            </>
          ) : (
            <>
              <Header name={user.email} />
              {settings.onboarded ? null : (
                <Onboarding
                  settings={settings}
                  isMobile={isMobile}
                  onSkip={handleSkip}
                />
              )}
              <div className={styles.stats}>
                <Stats isMobile={isMobile} />
              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  user: User;
  settings: Settings;
  dispatch: Function;
}

export default connect((props: any) => ({}))(Home);
