import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { IconLogout } from "@tabler/icons-react";

import "./NavBar.css";
import { User, Settings } from "../interfaces";
import AiSvg from "../theme/images/ai.svg";
import config from "../config";

const isDev = config.ENV === "staging" || config.ENV === "local";

const NavBar: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { user, logOut, path, settings } = props;
  return (
    <Navbar className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand as={Link} to={"/"}>
          <div className="logo"></div>
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link
            className={classNames("menu-list-link", {
              active: path === "/",
            })}
            as={Link}
            to="/"
          >
            <FormattedMessage id="nav.home" />
            {/*
              Not to deploy now
              {settings.badge ? (
                <span className="badge badge-visito">{settings.badge}</span>
              ) : null}
            */}
          </Nav.Link>
          <Nav.Link
            className={classNames("menu-list-link", {
              active: path.includes("/chats"),
            })}
            as={Link}
            to="/chats"
          >
            <FormattedMessage id="nav.inbox" />
          </Nav.Link>
          {settings.events ? (
            <Nav.Link
              className={classNames("navigation-link", {
                active: path.includes("/outbound"),
              })}
              as={Link}
              to="/outbound"
            >
              <FormattedMessage id="nav.outbound" />
            </Nav.Link>
          ) : null}
          <Nav.Link
            className={classNames(
              "navigation-link flex align-items-center gap-1",
              {
                active: path === "/ai",
              }
            )}
            as={Link}
            to="/ai"
          >
            <FormattedMessage id="nav.ai" />
            <img alt="ai-icon" src={AiSvg} />
          </Nav.Link>
        </Nav>
        <NavDropdown title={user.email}>
          <NavDropdown.Item as={Link} to="/settings">
            <FormattedMessage id="nav.settings" />
          </NavDropdown.Item>
          {isDev && (
            <NavDropdown.Item as={Link} to="/ai-settings">
              <FormattedMessage id="settings.ai" />
            </NavDropdown.Item>
          )}
          <NavDropdown.Divider />
          <NavDropdown.Item
            onClick={(e) => {
              e.preventDefault();
              logOut();
            }}
          >
            <IconLogout size={16} style={{ marginTop: "-2px" }} />{" "}
            <FormattedMessage id="nav.logout" />
          </NavDropdown.Item>
        </NavDropdown>
      </Container>
    </Navbar>
  );
};

interface ILayoutProps {
  user: User;
  logOut: Function;
  settings: Settings;
  path: string;
}

export default NavBar;
