import React from "react";
import { IonModal, isPlatform, IonHeader, IonToolbar, IonTitle, IonFooter } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import styles from "../../../components/new-conversation/styles.module.css"

const isMobile = isPlatform("android") || isPlatform("ios");

type InfoModalProps = {
  companyName: string;
  loading: boolean;
  onSubmit: (name: string) => void;
}

function InfoModal({ companyName, loading, onSubmit }: InfoModalProps) {
  const [name, setName] = React.useState("")

  const onBeforeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(name);
    return setName("")
  }

  return (
    <IonModal
      isOpen
      id={isMobile ? "playground-info-mobile" : "playground-info"}
      canDismiss={false}
    >
      {isMobile && (
        <IonHeader>
          <IonToolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <IonTitle>
              <FormattedMessage id="aiCompanyWelcomeTitle" values={{ company: companyName }} />
            </IonTitle>
          </IonToolbar>
        </IonHeader>
      )}
      <>
        <form
          id="new-playground"
          className="inner-content"
          onSubmit={onBeforeSubmit}
        >
          {!isMobile && (
            <div className={styles.header}>
              <h2 className="mb-0 fs-4">
                <FormattedMessage id="aiCompanyWelcomeTitle" values={{ company: companyName }} />
              </h2>
              <p className="mb-0 fs-3 description">
                <FormattedMessage id="aiCompanyFormDesc" />
              </p>
            </div>
          )}

          <div
            className={styles.body}
            style={{ padding: isMobile ? "1rem 1.25em" : "1rem" }}
          >
            {isMobile && (
              <p className="mb-0 fs-3 description">
                <FormattedMessage id="aiCompanyFormDesc" />
              </p>
            )}
            <div className="flex flex-column gap-2">
              <label htmlFor="name" className="fs-3">
                <FormattedMessage id="common.name" />
              </label>
              <input
                id="name"
                name="name"
                type="text"
                className={styles.input}
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
                required
              />
            </div>
          </div>

          {!isMobile && (
            <div
              className={styles.footer}
            >
              <button
                className="btn btn-visito-primary w-100"
                type="submit"
                style={{ pointerEvents: loading ? "none" : "auto" }}
              >
                {loading ? "..." : <FormattedMessage id="aiCompanyExplore" />}
              </button>
            </div>
          )}
        </form>
      </>
      {isMobile && (
        <IonFooter>
          <IonToolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div
              className={styles.footer}
              style={{ borderTop: "0px", padding: "0.5rem 1em" }} 
            >
              <button
                className="btn btn-visito-primary w-100"
                type="submit"
                form="new-playground"
                style={{ pointerEvents: loading ? "none" : "auto" }}
              >
                {loading ? "..." : <FormattedMessage id="aiCompanyExplore" />}
              </button>
            </div>
          </IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  )
}

export default InfoModal;
