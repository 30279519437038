import interceptRefreshToken from "./refreshToken";

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function parseError(response) {
  if (response.message === "Failed to fetch") {
    return new Promise((resolve) =>
      resolve("No Connection" /*response.statusText */),
    );
  }

  return response.json();
}

function baseAdapter(data) {
  return { data };
}

function errorAdapter(err) {
  return { err };
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  throw response;
}

export function requestOptions(method, body = {}, auth) {
  const headers = new Headers();
  const whichMethods = ["POST", "PUT"];
  const extend = whichMethods.indexOf(method) > -1;

  if (extend) {
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
  }

  if (auth) {
    const token = localStorage.getItem("token");
    headers.append("Authorization", `bearer ${token}`);
  }

  const options = {
    method,
    headers,
  };

  if (extend) {
    options.mode = "cors";
    options.redirect = "follow";
    options.body = JSON.stringify(body);
  }
  return options;
}

export function requestOptionsFormData(formData, auth) {
  const headers = new Headers();

  //headers.append("Content-Type", `multipart/form-data`);

  if (auth) {
    const token = localStorage.getItem("token");
    headers.append("Authorization", `bearer ${token}`);
  }

  const options = {
    method: "POST",
    headers,
  };

  options.mode = "cors";
  options.redirect = "follow";
  options.body = formData;

  return options;
}

export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(baseAdapter)
    .catch((err) =>
      parseError(err)
        .then((_err) => {
          console.log(_err);
          if (_err.name === "TokenError") {
            return interceptRefreshToken(
              { request, requestOptions },
              url,
              options,
            );
          }
          return errorAdapter(_err);
        })
        .catch((e) => {
          return errorAdapter("Unknown Error");
        }),
    );
}
