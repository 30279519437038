import { createReducer } from "@reduxjs/toolkit";
import { setSendLoading, setSendBody, cleanUpSendBody } from "../actions/send";

const loading: Boolean = false;
const body: String = "";

export const Send = createReducer(
  { loading, body },
  {
    [setSendLoading.type]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    [setSendBody.type]: (state, action) => ({
      ...state,
      body: action.payload.body,
    }),
    [cleanUpSendBody.type]: (state, action) => ({
      ...state,
      body: "",
    }),
  },
);
