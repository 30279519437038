
import { IonSelect, IonSelectOption } from "@ionic/react";
import datePickerStyles from "../../../theme/pickers-selects.module.css"

import { Location } from "../../../interfaces";

const LocationsSelect = (
  { value, options, onChange }:
  { value?: string; options: Location[], onChange: (location: string) => void }
) => (
  <div className={`${datePickerStyles.filtersContainer} my-4`}>
    <IonSelect
      labelPlacement="stacked"
      className={datePickerStyles.commonFilterSelect}
      value={value}
      style={{ width: "100%", minHeight: "34px", minWidth: "170px" }}
      onIonChange={(e) => onChange(e.target.value)}
    >
      {options.map((location) => (
        <IonSelectOption key={location.id} value={location.id}>
          {location.name}
        </IonSelectOption>
      ))}
    </IonSelect>
  </div>
);

export default LocationsSelect;
