import { forwardRef, InputHTMLAttributes } from 'react';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const FileInput = forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }, ref) => {
    return (
      <input
        ref={ref}
        type="file"
        {...props}
      />
    );
  }
);

FileInput.displayName = 'FileInput';

export default FileInput;
