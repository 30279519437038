import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import MissingIntegration from "../../../theme/images/missing-integration.svg";

import styles from "./styles.module.css";

const EmptyState = ({ isMobile } : { isMobile: boolean }) => (
  <div className="flex flex-column gap-4 w-100 align-items-center justify-content-center px-3 mt-5">
    <img src={MissingIntegration} width="200px" alt="" />
    <div className="flex flex-column gap-1">
      <p className={`mb-0 text-center ${isMobile ? "fs-4" : "fs-5"}`}>
        <FormattedMessage id="outbound.missingChannel" />
      </p>
      <p className={`${styles.description} mb-0 text-center ${isMobile ? "fs-3" : "fs-4"}`}>
        <FormattedMessage id="outbound.missingChannelDesc" />
      </p>
    </div>
    <Link to="/settings/channels" className={`btn btn-visito-primary px-5 ${isMobile ? "btn-sm" : ""}`}>
      <FormattedMessage id="outbound.missingChannelCTA" />
    </Link>
  </div>
)

export default EmptyState;
