const lang = {
  "common.and": "y",
  "common.or": "o",
  "common.loading": "Cargando",
  "common.done": "Listo",
  "common.continue": "Continuar",
  "common.accept": "Aceptar",
  "common.property": "Propiedad",
  "common.status": "Estado",
  "common.skip": "Saltar",
  "common.pause": "Pausar",
  "common.activate": "Activar",
  "common.back": "Atrás",
  "common.active": "Activo",
  "common.setup": "Configurar",
  "common.connect": "Conectar",
  "common.configure": "Configurar",
  "common.inactive": "Inactivo",
  "common.cancel": "Cancelar",
  "common.file": "Archivo",
  "common.photo": "Foto",
  "common.runAi": "Correr conversacional",
  "common.remove": "Borrar",
  "common.reset": "Reestablecer",
  "common.confirmInactive": "Si, desactivar",
  "common.error": "Error",
  "common.tryItLater": "Hubo un error, inténtalo más tarde",
  "common.success": "Exitoso",
  "common.today": "Hoy",
  "common.week": "Esta semana",
  "common.7days": "Últimos 7 días",
  "common.30days": "Últimos 30 días",
  "common.thisMonth": "Este mes",
  "common.lastMonth": "Mes pasado",
  "common.custom": "Custom",
  "common.date": "Fecha",
  "common.name": "Nombre",
  "common.message": "Mensaje",
  "common.noResults": "No hay registros para el rango de fechas seleccionado",
  "common.results": "{total} resultados",
  "common.body": "Mensaje",
  "common.tags": "Etiquetas",
  "common.save": "Guardar",
  "common.saveClose": "Guardar y cerrar",
  "common.submitChanges": "Enviar cambios",
  "common.updating": "Actualizando...",
  "common.newConversation": "Nueva conversación",
  "common.create": "Crear",
  "common.phone": "Número de teléfono",
  "common.contactName": "Nombre del contacto",
  "common.optional": "opcional",
  "common.signUp": "Registrarse",
  "common.copyLink": "Link copiado",
  "common.share": "Compartir",
  "common.virtualAssistant": "Asistente Virtual",

  "settings.pageTitle": "Ajustes",
  "settings.pageDescription": "Ajusta tus preferencias",
  "settings.ai": "Ajustes de IA",
  "settings.accountTitle": "Cuenta",
  "settings.language": "Idioma",
  "settings.settingsTitle": "Ajustes",
  "settings.channels": "Canales",
  "settings.integrations": "Integraciones",
  "settings.teams": "Equipo",
  "settings.payment": "Facturación",
  "esc.needsReview": "Revisión",
  "esc.escalation": "Ayuda",
  "esc.clickToView": "Clica para ver",
  "esc.tapToView": "Toca para ver",
  "esc.solve": "Resolver la escalada",
  "nav.inbox": "Chats",
  "nav.home": "Inicio",
  "nav.outbound": "Eventos",
  "nav.ai": "IA",
  "nav.settings": "Ajustes",
  "nav.logout": "Salir",
  "nav.trial":
    "Tu prueba gratuita termina en {count, plural, one {# día} other {# días}}",
  "nav.payment": "Tu prueba gratuita ha terminado.",
  "nav.paymentCTA": "Suscríbete hoy",
  "ai-settings.title": "Ajustes de IA",
  "ai-settings.paneTitle": "Configuración de Inteligencia Artificial",
  "ai-settings.businessInfo": "Información de la propiedad",
  "ai-settings.businessInfoDescription":
    "Proporciona información general sobre tu negocio.",
  "ai-settings.guestInfo": "Información solo para huéspedes",
  "ai-settings.guestInfoDescription":
    "Proporciona información específica para huéspedes con una reserva existente.",
  "ai-settings.notifications": "Notificaciones",
  "ai-settings.notificationsDescription":
    "Descarga la aplicación Visito y recibe notificaciones push para conversaciones no resueltas.",
  "ai-settings-intructions.title": "Ajustes",
  aiSettingsWorking: "Estamos preparando tu información",
  aiSettingsKnowledge: "Base de conocimientos",
  aiSettingsKnowledgeDesc:
    "A continuación se muestran varios métodos que puede utilizar para proporcionar información para entrenar la IA.",
  aiSettingsAlert:
    "Aquí puede proporcionar información para entrenar la IA. Pruebe la IA de la derecha para ver qué experimentarán sus clientes.",
  aiSettingsFreeText: "Texto libre",
  aiSettingsReset: "Limpiar conversación",
  aiSettingsAssitant: "¡Conoce a Lana! Tu asistente virtual",
  aiSettingsAssitantDesc:
    "Aquí es donde puede experimentar e interactuar con Lana para comprender exactamente qué experimentarán sus clientes con las respuestas de la IA.",
  aiSettingsSaving: "Guardando cambios...",
  aiExceededLength: "Excediste la longitud de caracteres",
  aiCompanyTitle: "{company} asistente virtual",
  aiCompanyWelcomeTitle: "Bienvenido al asistente virtual de {company}",
  aiCompanyFormDesc: "Introduce tu nombre para empezar",
  aiCompanyExplore: "Continuar",
  aiShare: "Comparte tu asistente virtual",

  "auth.login.title": "Bienvenido a Visito",
  "auth.login.email": "Correo electrónico",
  "auth.login.password": "Contraseña",
  "auth.login.login": "Iniciar sesión",
  "auth.login.registerLabel": "¿Nuevo en Visito?",
  "auth.login.register": "Crear una cuenta",
  "auth.login.forgotPassword": "Click aquí",
  "auth.login.forgotLabel": "¿Olvidó su contraseña?",
  "auth.register.title": "Comienza gratis",
  "auth.register.company": "Nombre de la empresa",
  "auth.register.web": "Sitio web",
  "auth.register.email": "Correo electrónico",
  "auth.register.password": "Contraseña",
  "auth.register.register": "Registrarse",
  "auth.register.loginLabel": "¿Ya tienes una cuenta?",
  "auth.register.login": "Iniciar sesión",
  "auth.register.loginTerms": "Términos de Servicio",
  "auth.register.loginPrivacy": "Política de Privacidad",
  "auth.register.continuing": "Al continuar, aceptas nuestros",
  "auth.register.titleCloudbeds": "Crea tu cuenta de Visito",
  "auth.registerUser.title": "Configura tu cuenta",
  "auth.registerUser.subtitle":
    "Por favor, establece tu contraseña para comenzar.",

  "auth.forgotPassword.title": "Restablecer contraseña",
  "auth.forgotPassword.email": "Email",
  "auth.forgotPassword.submit": "Enviar",
  "auth.forgotPassword.success":
    "El enlace para restablecer tu contraseña se ha enviado a su email",
  "auth.forgotPassword.error":
    "Email no encontrado. Por favor, verifica e intenta de nuevo.",
  "auth.forgotPassword.description":
    "Ingresa la dirección de correo electrónico asociada a tu cuenta y te enviaremos un enlace para restablecer tu contraseña",
  "auth.resetPassword.title": "Restablecer contraseña",
  "auth.resetPassword.password": "Ingrese su nueva contraseña",
  "auth.resetPassword.cancel": "Cancelar",
  "auth.resetPassword.submit": "Restablecer",
  "auth.resetPassword.success":
    "Tu contraseña ha sido restablecida con éxito. Ahora puedes iniciar sesión en tu cuenta usando tu nueva contraseña.",
  "auth.resetPassword.description":
    "Elige una nueva contraseña abajo para restablecerla",
  "auth.userInvite.success": "Invitación aceptada correctamente",
  "channels.title": "Canales",
  "channels.account": "Cuenta",
  "channels.autoReplies": "Respuestas automáticas de IA",
  "channels.waTitle": "Conecta tus números de WhatsApp empresariales",
  "channels.waDescription":
    "Automatiza conversaciones de WhatsApp o responde manualmente en el chat en vivo.",
  "channels.igTitle": "Conecta tu cuenta de Instagram",
  "channels.igDescription":
    "Automatiza los mensajes del buzón de entrada o responde manualmente en el chat en vivo.",
  "channels.fmTitle": "Conectar Facebook Messenger",
  "channels.fmDescription":
    "Automatiza conversaciones de Messenger o responde manualmente usando la aplicación Messenger.",
  "contactDetail.title": "Perfil de contacto",
  "contactDetail.actions": "Acciones",
  "contactDetail.bookings": "Reservas",
  "contactDetail.followers": "seguidores",
  "chats.newMessageInput": "Ingrese el mensaje...",
  "chats.searchContact": "Buscar contacto",
  "chats.aiAuto": "Respuestas automáticas de IA",
  "chats.markAsOpen": "Marcar como revisión",
  "chats.markAsClose": "Marcar como resuelto",
  "chats.all": "Todos",
  "chats.manual": "Manual",
  "chats.filter": "Filtrar",
  "chats.escalated": "Necesita Revisión",
  "chats.emptyStateTitle": "Aún no hay conversaciones",
  "chats.emptyStateSubtitle": "Tu lista de chat espera tu primer mensaje",
  "chats.emptyStateEscTitle": "No hay conversaciones en revisión",
  "chats.emptyStateEscSubtitle": "¡Parece que todo está en orden!",

  "chats.emptyWelcomeTitle": "No hay conversación seleccionada",
  "chats.emptyWelcomeSubtitle":
    "Elige una conversación de la izquierda para ver sus mensajes.",
  "integrations.title": "Integraciones",
  "integrations.propertiesListTitle": "Propiedades",
  "integrations.noProperties": "No se encontraron propiedades",
  "integrations.acceptCloudbedsNewIntegration":
    "Por favor, acepte la nueva integración entrante de Cloudbeds",
  "teams.title": "Equipo",
  "teams.inviteMember": "Invitar miembro",
  "teams.selectorLabel": "Rol",
  "teams.rol.admin": "Administrador",
  "teams.rol.user": "Usuario",
  "teams.emailLabel": "Email",
  "teams.button": "Invitar",
  "teams.message.success":
    "Un enlace de invitación ha sido enviado a su correo.",
  "teams.passwordLabel": "Contraseña",
  "teams.buttonAccept": "Aceptar",
  "teams.inviteInfo": "Aceptar invitación a Equipos",
  "teams.modalMessageTitle.success": "Invitación exitosa",
  "teams.modalMessageTitle.error": "Error al enviar invitación",
  "teams.modal.button": "Cerrar",
  "invitedList.Members": "Miembros",
  "invitedList.emailLabel": "Email",
  "invitedList.roleLabel": "Rol",
  "invitedList.dateLabel": "Se unió",
  "invitedList.title.deleteSuccess": "Eliminar usuario",
  "invitedList.message.deleteSuccess": "Usuario eliminado correctamente",
  "invitedList.message.deleteError": "Error al eliminar el usuario",
  "payment.title": "Facturación",
  "payment.subTitle":
    "Administra tus métodos de pago, suscripciones y detalles de facturación.",
  "payment.monthly": "Mensual",
  "payment.annual": "Anual",
  "payment.currentPlan": "Plan Actual",
  "payment.managePlan": "Administrar Plan",
  "payment.billingEmail": "Email de Facturación",
  "language.title": "Idioma",
  "language.subTitle":
    "Seleccione el idioma al que desea cambiar la plataforma",
  "language.selectorLabel": "Seleccionar Idioma",
  "language.english": "Inglés",
  "language.spanish": "Español",
  "language.languages": "Idiomas",
  "language.en_US": "Inglés",
  "language.es_MX": "Español",

  "home.statsTitle": "Estadísticas de los últimos 30 días",
  "home.pageTitle": "Hola",
  "home.pageDescription": "Bienvenido a tu dashboard de Visito",

  "home.skip": "Saltar introducción",

  "home.onboardingTitle": "Empezar",
  "home.onboardingDescription":
    "Completa los siguientes pasos para configurar tu cuenta",

  "home.title_account": "Crea tu cuenta",
  "home.description_account":
    "Administra todas las comunicaciones en una sola bandeja de entrada de Visito",
  "home.title_wa": "Conectar WhatsApp",
  "home.description_wa":
    "Alcanza a más de 2 mil millones de clientes en todo el mundo",

  "home.title_fm": "Conectar Facebook Messenger",
  "home.description_fm":
    "Impulsa el soporte y las reservas con Facebook Messenger",

  "home.title_ig": "Conectar Instagram",
  "home.description_ig":
    "Conecta la bandeja de entrada de tu cuenta de Instagram para empezar a recibir mensajes",

  "home.title_ai": "Configura las fuentes de datos de la IA",
  "home.description_ai": "Programa tu agente de IA con la información correcta",

  "home.pendingItems": "acciones pendientes",

  "home.uniqueContacts": "Contactos Únicos",
  "home.totalMessages": "Mensajes Totales",
  "home.noStats":
    "Parece que aún no hay datos. Por favor, vuelva más tarde o ajuste sus filtros.",

  "outbound.event": "Evento",
  "outbound.pageTitle": "Eventos",
  "outbound.pageDescription":
    "Mejora la experiencia del huésped con mensajes automatizados durante todo el proceso de reserva",
  "outbound.viewEvent": "Ver evento",
  "outboud.messagesSent": "Mensajes enviados en los últimos 30 días",
  "outbound.reservation":
    "Después de que un huésped complete su reserva, el huésped recibe un mensaje de confirmación con detalles de su reserva.",
  "outbound.time_reservation": "Enviado inmediatamente",
  "outbound.arrival":
    "Antes de la llegada, el huésped recibe un mensaje con información útil sobre su estancia.",
  "outbound.time_arrival": "2 días antes de la llegada",
  "outbound.stay":
    "Después de que el huésped haga el check-in, el huésped recibe un mensaje con instrucciones especiales e información útil.",
  "outbound.time_stay": "Enviado inmediatamente después de la llegada",
  "outbound.after_departure":
    "Después de que el huésped haga el check-out, el huésped recibe un mensaje de agradecimiento junto con una solicitud de retroalimentación.",
  "outbound.time_after_departure": "1 día después de la salida",
  "outbound.inactiveTitle":
    "¿Estás seguro de que deseas desactivar este evento?",
  "outbound.inactiveDesc":
    "Desactivar este evento provocará que no se envíen estos mensajes.",
  "outbound.disabling": "Desactivando el evento...",
  "outbound.cardTitle": "Eventos de Salida",
  "outbound.recentActivity": "Actividad Reciente",
  "outbound.noActivity": "Sin actividad",
  "outbound.missingChannel":
    "¡Ups! Aún no has conectado un número de WhatsApp a tu cuenta.",
  "outbound.missingChannelDesc":
    "Conecta tu número de WhatsApp para interactuar con tus huéspedes durante las reservas, las llegadas y después de su partida.",
  "outbound.missingChannelCTA": "Haga clic aquí para conectar",
  "outbound.modifyTemplate": "Editar plantilla",
  "outbound.templateTitle": "Editar Plantilla: {template}",
  "outbound.templateLastUpdate":
    "Última modificación: {date} (Modificado {count, plural, one {# vez} other {# veces}} en 30 días)",
  "outbound.templateLangDesc":
    "Modifique el mensaje que desea enviar a los huéspedes en {lang}",
  "outbound.templatePreview": "Vista previa del mensaje",
  "outbound.templatePreviewDesc":
    "A continuación se muestra cómo se verá su mensaje",
  "outbound.templateVarsInfo":
    "Es posible que deba asegurarse que las etiquetas se ingresen correctamente. A continuación se listan para saber qué etiquetas puede usar",
  "outbound.templateVardDesc":
    "Utilice estas etiquetas en el cuerpo del mensaje",
  "outbound.templateTag": "Etiqueta",
  "outbound.templateFunction": "Función",
  "outbound.template_confirmationId": "Número de confirmación",
  "outbound.template_username": "El nombre completo del contacto",
  "outbound.template_checkin": "Fecha de entrada del huésped",
  "outbound.template_checkout": "Fecha de salida del huésped",
  "outbound.template_status_PENDING":
    "Se están revisando los nuevos cambios de la plantilla. Este proceso puede tardar hasta 24 para que se aprueben los nuevos cambios. No puedes modificar la plantilla enviada hasta que se complete la revisión.",
  "outbound.template_status_REJECTED":
    "Sus cambios han sido rechazados por el siguiente motivo: {reason}",
  "outbound.template_status_LESS_THAN_ONE_DAY":
    "Espere 24 horas desde la última actualización para editar la plantilla nuevamente",
  "outbound.templateReviewTitle":
    "¿Está seguro de que desea enviar estos nuevos cambios para su revisión?",
  "outbound.templateReviewDesc":
    "La revisión puede tardar hasta 24 horas para ser aprobado. No podrá realizar más cambios hasta que finalice la revisión.",
  "outbound.templateSend": "Sí, enviar para revisión",
  "outbound.templateSendForReview":
    "La plantilla se envió a revisión, el proceso de aprobación puede tardar hasta 24 horas",

  "onboarding.getStarted": "Comenzar",
  "onboarding.pageTite": "Introducción",
  "onboarding.stepsTitle":
    "Elige un momento para completar tu incorporación a Visito",
  "onboarding.step1":
    "Revisar la información de tu propiedad y la configuración del chat",
  "onboarding.step2":
    "Configurar tus canales de comunicación, incluyendo WhatsApp e Instagram",
  "onboarding.step3": "Resumen de las funciones y capacidades de la cuenta",
  "onboarding.step4":
    "Enviar mensajes de prueba y habilitar Visito en tus propiedades digitales",
  "onboarding.step5": "¡Responder cualquier otra pregunta que puedas tener!",

  "onboarding.businessInfo": "Detalles de tu negocio",
  "onboarding.businessInfoWorking":
    "¡Casi estamos! Esto debería tomar menos de un minuto...",
  "onboarding.businessInfoDone": "Todo listo.",
  "onboarding.businessInfoContinue":
    "Revisar la información y continuar. Siempre puedes editar esto más tarde.",
  "onboarding.channelsDone": "Todo listo!",
  "onboarding.continueToDashboard": "Continuar al dashboard",
  "onboarding.igConnect": "Conectar tu cuenta automáticamente.",
  "onboarding.igConnected": "Tu cuenta de Instagram ha sido conectada.",
  "onboarding.waConnect": "Comienza en minutos.",
  "onboarding.waConnected":
    "Tu número de teléfono de WhatsApp ha sido conectado.",
  "onboarding.channelsTitle": "Conecta Instagram o WhatsApp para empezar:",

  "newConv.Title": "Crear una nueva conversación",
  "newConv.Desc":
    "Ingrese el número de Whatsapp a continuación para crear una nueva conversación.",
  "newConv.invalidPhone": "Parece que has ingresado un número incorrecto.",
  "newConv.success": "Mensaje enviado exitosamente",
};

export default lang;
