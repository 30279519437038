import { connect } from "react-redux";
import { useState } from "react";
import { Location } from "history";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import SkeletonLoader from "../../../components/SkeletonLoader";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  useIonViewDidEnter,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonBackButton,
} from "@ionic/react";

import { IconCheck, IconX } from "@tabler/icons-react";

import { deserialize } from "../../../utils/serialize";
import Api from "../../../api/integrations";

const Integrations: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  interface Integration {
    _id: string;
    name: string;
    accessToken: string;
  }

  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [code, setCode] = useState("");
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);

  const requestIntegrations = async () => {
    setLoadingList(true);
    const { data, err } = await Api.getIntegrations();
    if (data) {
      setIntegrations(data.integrations);
    }
    setLoadingList(false);
    if (err) {
      console.log(err);
    }
  };

  useIonViewDidEnter(() => {
    const { search } = props.location;
    const params = deserialize(search);
    const { code } = params;
    requestIntegrations();
    if (code) {
      setCode(code);
    }
  });

  const integrate = async (code: string) => {
    setLoading(true);
    const { data, err } = await Api.cloudbedsCode(code);
    setLoading(false);
    if (err) {
      return setErr(err.message);
    }
    if (data) {
      requestIntegrations();
      setSuccess(
        "You have succesfully accepted the integration with your cloudbeds account"
      );
    }
  };

  const cloudbedsIntegration = (
    <>
      <div className="view-pane-title mt-4">CLOUDBEDS NEW INTEGRATION</div>
      <div className="view-pane">
        <div className="view-pane-body">
          <h5>Cloudbeds</h5>
          <p> Please accept new incoming integration from cloudbeds</p>

          {!err && !success ? (
            <button
              className="btn btn-visito-primary"
              color="success"
              onClick={() => integrate(code)}
              disabled={loading}
            >
              {loading ? "working..." : "Accept"}
            </button>
          ) : (
            <>
              {err && (
                <IonText color="danger">
                  <IconX width={18} /> {err}
                </IonText>
              )}
              {success && (
                <IonText color="success">
                  <IconCheck width={18} /> {success}
                </IonText>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/settings`} />
          </IonButtons>
          <IonTitle>Integrations</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <div className="view-wrapper">
          {code && cloudbedsIntegration}
          <div className="view-pane-title mt-4">
            <FormattedMessage id="integrations.propertiesListTitle" />
          </div>
          <IonList inset={true}>
            {loadingList ? (
              <SkeletonLoader rows={1} />
            ) : (
              integrations.map((i) => (
                <IonItem key={i._id}>
                  <IonLabel>{i.name}</IonLabel>
                  <span
                    className={classNames("badge", {
                      "text-bg-success": i.accessToken,
                      "text-bg-light": !i.accessToken,
                    })}
                  >
                    {i.accessToken ? (
                      <FormattedMessage id="common.active" />
                    ) : (
                      <FormattedMessage id="common.inactive" />
                    )}
                  </span>
                </IonItem>
              ))
            )}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({
  outbound: props.outboundEvents,
}))(Integrations);
