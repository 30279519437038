import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { IconWorld } from "@tabler/icons-react";
import "./NavBarAuth.css";

const NavBarAuth: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  return props.hide ? null : (
    <Navbar className="navbar-auth" fixed="top">
      <Container fluid>
        <Navbar.Brand>
          <a href="/">
            <div className="logo"></div>
          </a>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

interface ILayoutProps {
  hide: boolean;
  switchLanguage?: Function;
  language?: string;
}

const FooterAuth: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const switchLanguage = (lang: string) => {
    if (props.switchLanguage) {
      props.switchLanguage(lang);
    }
  };

  return props.hide ? null : (
    <Navbar className="navbar-auth-footer" fixed="bottom">
      <Container fluid>
        <div className="nav-copyright">© {new Date().getFullYear()} Visito</div>
      </Container>
      <Nav className="nav-language">
        <IconWorld />
        <a
          className={props.language === "en" ? `active` : ``}
          onClick={() => switchLanguage("en")}
        >
          ENG
        </a>
        <a
          className={props.language === "es" ? `active` : ``}
          onClick={() => switchLanguage("es")}
        >
          ESP
        </a>
      </Nav>
    </Navbar>
  );
};

export default NavBarAuth;

export { FooterAuth };
