import {
  getTodayForFilter,
  getCurrentWeek,
  getLastDays,
  getCurrentMonth,
  getLastMonth,
} from "../../utils/moment";

const COMMON_FILTERS = [
  { value: getTodayForFilter(), label: "common.today" },
  { value: getCurrentWeek(), label: "common.week" },
  { value: getLastDays({ days: 30 }), label: "common.30days" },
  { value: getCurrentMonth(), label: "common.thisMonth" },
  { value: getLastMonth(), label: "common.lastMonth" },
  { value: "custom", label: "common.custom" },
];

export const DIMENSION_FILTERS = [
  { value: "uniqueContacts", label: "home.uniqueContacts" },
  { value: "totalMessages", label: "home.totalMessages" },
];

export default COMMON_FILTERS;
