import { connect } from "react-redux";
import { useState } from "react";
import { Location } from "history";

import { FormattedMessage } from "react-intl";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  useIonViewDidEnter,
  IonItem,
  IonLabel,
  IonList,
  IonBackButton,
  IonToggle,
} from "@ionic/react";

import Api from "../../../api/channels";

import FacebookSdk from "../../../components/FacebookSdk";
import SkeletonLoader from "../../../components/SkeletonLoader";

const Channels: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  interface Whatsapp {
    id: string;
    phoneNumberId: string;
    active: boolean;
  }

  interface Instagram {
    id: string;
    name: string;
    active: boolean;
  }

  interface Messenger {
    pageId: string;
    name: string;
    active: boolean;
  }

  const [whatsapp, setWhatsapp] = useState<Whatsapp[]>([]);
  const [instagram, setInstagram] = useState<Instagram[]>([]);
  const [messenger, setMessenger] = useState<Messenger[]>([]);
  const [loading, setLoading] = useState(true);

  const requestChannels = async () => {
    const { data, err } = await Api.getChannels();

    if (data) {
      const { channels } = data;
      setWhatsapp(channels.wa || []);
      setInstagram(channels.ig || []);
      setMessenger(channels.fm || []);
      setLoading(false);
    }
    if (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const createChannels = async (meta: any) => {
    const { err } = await Api.createChannels({ meta });

    if (err) {
      console.log(err);
    }
    requestChannels();
  };

  const onLoginSucces = () => {
    setLoading(true);
  };

  useIonViewDidEnter(() => {
    requestChannels();
  });

  const toggleChannel = async (id: string, active: boolean) => {
    const { err } = await Api.toggleChannels(id, active);
    if (err) {
      console.log(err);
    }
  };

  const toggleWa = (w: Whatsapp, active: boolean) => {
    toggleChannel(w.phoneNumberId, !w.active);
    const newWa = whatsapp.map((wa) => {
      if (wa.phoneNumberId === w.phoneNumberId) {
        wa.active = !wa.active;
      }
      return wa;
    });
    setWhatsapp(newWa);
  };

  const toggleInsta = (i: Instagram, active: boolean) => {
    toggleChannel(i.id, active);
    const newIg = instagram.map((ig) => {
      if (ig.id === i.id) {
        ig.active = active;
      }
      return ig;
    });
    setInstagram(newIg);
  };

  const toggleMessenger = (m: Messenger, active: boolean) => {
    toggleChannel(m.pageId, active);
    const newFm = messenger.map((fm) => {
      if (fm.pageId === m.pageId) {
        fm.active = !fm.active;
      }
      return fm;
    });
    setMessenger(newFm);
  };

  const instaList = (
    <IonList inset={true}>
      {instagram.map((i) => (
        <div key={i.id}>
          <IonItem>
            <IonLabel>
              <strong>{i.name}</strong>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonToggle
              checked={i.active}
              onIonChange={(event: CustomEvent<{ checked: boolean }>) =>
                toggleInsta(i, event.detail.checked)
              }
              className="visito-toggle"
            >
              <IonLabel>
                <FormattedMessage id="channels.autoReplies" />
              </IonLabel>
            </IonToggle>
          </IonItem>
        </div>
      ))}
    </IonList>
  );

  const messengerList = (
    <IonList inset={true}>
      {messenger.map((m) => (
        <div key={m.pageId}>
          <IonItem>
            <IonLabel>
              <strong>{m.name}</strong>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonToggle
              checked={m.active}
              onIonChange={(event: CustomEvent<{ checked: boolean }>) =>
                toggleMessenger(m, event.detail.checked)
              }
              className="visito-toggle"
            >
              <IonLabel>
                <FormattedMessage id="channels.autoReplies" />
              </IonLabel>
            </IonToggle>
          </IonItem>
        </div>
      ))}
    </IonList>
  );

  const empty = (
    <div className="view-pane">
      <div className="view-pane-body">
        <p>
          <FormattedMessage id="channels.igTitle" />
        </p>
        <small className="color-step-400">
          <FormattedMessage id="channels.igDescription" />
        </small>
        <div>
          <FacebookSdk
            onLoginSucces={() => onLoginSucces()}
            onCodeSucces={createChannels}
            className="btn btn-visito-primary mt-2"
            config={{ id: "363287656104000", type: "user", platform: "ig" }}
            text="Connect Instagram"
          />
        </div>
      </div>
    </div>
  );

  const emptyMessenger = (
    <div className="view-pane">
      <div className="view-pane-body">
        <p>
          <FormattedMessage id="channels.fmTitle" />
        </p>
        <small className="color-step-400">
          <FormattedMessage id="channels.fmDescription" />
        </small>
        <div>
          <FacebookSdk
            onLoginSucces={() => onLoginSucces()}
            onCodeSucces={createChannels}
            className="btn btn-visito-primary mt-2"
            config={{
              id: "1389965081634294",
              type: "user",
              platform: "fm",
            }}
            text="Connect Messenger"
          />
        </div>
      </div>
    </div>
  );

  const waList = (
    <IonList inset={true}>
      {whatsapp.map((w) => (
        <div key={w.id}>
          <IonItem>
            <IonLabel>
              <strong>{w.phoneNumberId}</strong>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonToggle
              checked={w.active}
              onIonChange={(event: CustomEvent<{ checked: boolean }>) =>
                toggleWa(w, event.detail.checked)
              }
              className="visito-toggle"
            >
              <IonLabel>
                <FormattedMessage id="channels.autoReplies" />
              </IonLabel>
            </IonToggle>
          </IonItem>
        </div>
      ))}
    </IonList>
  );

  const emptyWa = (
    <div className="view-pane">
      <div className="view-pane-body">
        <p>
          <FormattedMessage id="channels.waTitle" />
        </p>
        <small className="color-step-400">
          <FormattedMessage id="channels.waDescription" />
        </small>
        <div>
          <button className="btn btn-visito-primary mt-2">
            Get me started
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/settings`} />
          </IonButtons>
          <IonTitle>
            {" "}
            <FormattedMessage id="channels.title" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <div className="view-wrapper">
          <div className="view-pane-title mt-4"> INSTAGRAM</div>
          {loading ? (
            <SkeletonLoader rows={1} />
          ) : instagram.length ? (
            instaList
          ) : (
            empty
          )}

          <div className="view-pane-title list"> WHATSAPP</div>

          {loading ? (
            <SkeletonLoader rows={1} />
          ) : whatsapp.length ? (
            waList
          ) : (
            emptyWa
          )}

          <div className="view-pane-title list"> MESSENGER</div>

          {loading ? (
            <SkeletonLoader rows={1} />
          ) : messenger.length ? (
            messengerList
          ) : (
            emptyMessenger
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({
  outbound: props.outboundEvents,
}))(Channels);
