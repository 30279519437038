import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}`;

const Teams = {
  createInvite(body) {
    const options = requestOptions("POST", body, true);
    const url = `${baseUrl}/team`;
    return request(url, options);
  },
  getInvite() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/team`;
    return request(url, options);
  },
  removeInvite({ id }) {
    const options = requestOptions("PUT", {}, true);
    const url = `${baseUrl}/team/${id}`;
    return request(url, options);
  },
};

export default Teams;
