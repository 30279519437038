import React, { ForwardedRef, MouseEvent, ReactNode } from "react";
import { IconDotsVertical } from "@tabler/icons-react";

interface CustomToggleProps {
  children: ReactNode;
  title: string;
  onClick: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const CustomToggle = React.forwardRef(
  (
    { children, onClick }: CustomToggleProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => (
    <a
      className="action-dots"
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <IconDotsVertical />
    </a>
  ),
);

export default CustomToggle;
