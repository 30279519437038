import config from "../config";
import request, {
  requestOptions,
  requestOptionsFormData,
} from "../utils/request";

import algoliasearch from "algoliasearch";

const client = algoliasearch(
  "5LL3G2AWCN",
  "3a3fed8edb979722a689aae36a7ff4cc" // search only API key, not admin API key
);

const index = client.initIndex("contacts");

const baseUrl = `${config.API_ROOT}/app/contacts`;

const Contacts = {
  search: async (string) => {
    try {
      const company = localStorage.getItem("company");

      const filter = {
        filters: `company:${company}`,
      };

      const results = await index.search(
        string,
        company === "all" ? {} : filter
      );
      return { data: results.hits };
    } catch (err) {
      return { err };
    }
  },
  get() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/`;
    return request(url, options);
  },
  getMessages(contactId) {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/${contactId}/messages`;
    return request(url, options);
  },
  postMessages(contactId, body) {
    const options = requestOptions("POST", body, true);
    const url = `${baseUrl}/${contactId}/messages`;
    return request(url, options);
  },
  uploadMedia(contactId, formData) {
    const options = requestOptionsFormData(formData, true);
    const url = `${baseUrl}/${contactId}/media`;
    return request(url, options);
  },
  readMessages(contactId, messageId) {
    const options = requestOptions("POST", {}, true);
    const url = `${baseUrl}/${contactId}/messages/${messageId}`;
    return request(url, options);
  },
  putEnabled(contactId, body) {
    const options = requestOptions("PUT", body, true);
    const url = `${baseUrl}/${contactId}/enabled`;
    return request(url, options);
  },
  escalateContact(contactId, body) {
    const options = requestOptions("PUT", body, true);
    const url = `${baseUrl}/${contactId}/manual-escalation`;
    return request(url, options);
  },
  runConversational(contactId) {
    const options = requestOptions("POST", {}, true);
    const url = `${baseUrl}/${contactId}/run-converational`;
    return request(url, options);
  },
  putSolveEscalations(contactId) {
    const options = requestOptions("PUT", {}, true);
    const url = `${baseUrl}/${contactId}/solve-escalations`;
    return request(url, options);
  },
};

export default Contacts;
