import { useState, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import {
  IonPage,
  IonContent,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonToggle,
  IonLoading,
  IonAlert,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";
import { IconClockHour3, IconCalendar } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import DatePicker, { registerLocale } from "react-datepicker";
import { enUS, es } from "date-fns/locale";

import OutboundEventsApi from "../../../../api/outbound-events";
import SkeletonLoader from "../../../SkeletonLoader";
import {
  getLastDays,
  parseDateForFilter,
  parseFilteredDates,
} from "../../../../utils/moment";

import MessageStatus, { EventStatus } from "../badge";

import Pagination from "../../../common/pagination";
import datePickerStyles from "../../../../theme/pickers-selects.module.css";
import globalOutboundStyles from "../../styles.module.css";
import COMMON_FILTERS from "./constants";
import OutboundLogsProps from "../types";

import styles from "./styles.module.css";
import tableStyles from "./../../../../theme/table.module.css";

import "react-datepicker/dist/react-datepicker.css";

const Templates = lazy(() => import("../../templates"));

registerLocale("es", es);
registerLocale("en", enUS);

const lastWeekFilter = getLastDays({ days: 7 });
const [startDate, endDate] = parseFilteredDates(lastWeekFilter);

const OutboundLogs = (props: OutboundLogsProps) => {
  const { id } = props.match.params;

  const { formatMessage, locale } = useIntl();

  const [range, setRange] = useState({
    start: startDate,
    end: endDate,
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [commonFilter, setCommonFilter] = useState(lastWeekFilter);
  const [dateQuery, setDateQuery] = useState(lastWeekFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [openTemplates, setOpenTemplates] = useState(false);

  const {
    isLoading: isLoadingEventLogs,
    data: outboundEventLogsData,
    refetch: refetchEventLogs,
  } = useQuery({
    queryFn: () =>
      OutboundEventsApi.getOutboundEventsLogs({
        id,
        date: dateQuery,
        page: currentPage,
      }),
    queryKey: ["getOutboundEventsLogs", id, dateQuery, currentPage],
    refetchOnWindowFocus: false,
  });

  const onHideAlert = () => {
    return setOpenAlert(() => false);
  };

  const { mutate: mutateUpdateEventStatus, isPending: isUpdatingEvent } =
    useMutation({
      mutationFn: OutboundEventsApi.putOutboundEvent,
      onSuccess: () => {
        refetchEventLogs();
        return onHideAlert();
      },
      onError: () => {
        onHideAlert();
      },
    });

  const onDisableEvent = () => {
    mutateUpdateEventStatus({ id, active: false });
  };

  const onChangeCommonFilter = (filter: string) => {
    const [start, end] = parseFilteredDates(filter);
    setRange((prevState) => ({ ...prevState, start, end }));
    setCommonFilter(() => filter);
    return setDateQuery(filter);
  };

  const onChangeDates = (dates: [Date, Date]) => {
    const [start, end] = dates;

    if (start && end) {
      setDateQuery(
        () => `${parseDateForFilter(start)},${parseDateForFilter(end)}`
      );
      setCommonFilter("custom");
    }
    return setRange((prevState) => ({ ...prevState, start, end }));
  };

  const onDisplayAlert = (active: boolean) => {
    if (!active) {
      return setOpenAlert(() => true);
    }
    return mutateUpdateEventStatus({ id, active: true });
  };

  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper container">
          {isLoadingEventLogs ? (
            <div className="visito-card visito-block">
              <div className="visito-card-body">
                <SkeletonLoader rows={9} />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.breadcrumbContainer}>
                <IonBreadcrumbs mode="ios">
                  <IonBreadcrumb
                    routerLink="/outbound"
                    className="breadcrumb-parent"
                  >
                    <FormattedMessage id="nav.outbound" />
                  </IonBreadcrumb>
                  <IonBreadcrumb
                    routerLink={`/outbound/${outboundEventLogsData?.outboundEvent.id}`}
                  >
                    {outboundEventLogsData?.outboundEvent.name}
                  </IonBreadcrumb>
                </IonBreadcrumbs>
              </div>

              <div className="flex flex-column gap-2 mt-4 mb-4">
                <div className="flex justify-content-between gap-2">
                  <div className="flex gap-2 align-items-center">
                    <h1 className="page-title mb-0 mt-0">
                      {outboundEventLogsData?.outboundEvent.name}
                    </h1>
                    <EventStatus
                      active={
                        outboundEventLogsData?.outboundEvent.active || false
                      }
                    />
                  </div>
                  <div className="flex align-items-center gap-4">
                    <div className="flex align-items-center gap-2">
                      <span className="fs-2">Status</span>
                      <IonToggle
                        checked={outboundEventLogsData?.outboundEvent.active}
                        onIonChange={(ev) => onDisplayAlert(ev.detail.checked)}
                        mode="ios"
                        className="ios visito-toggle"
                      />
                    </div>
                    {outboundEventLogsData?.outboundEvent?.templates &&
                      outboundEventLogsData.outboundEvent.templates?.length > 0 && (
                        <button
                          className="btn btn-visito-primary btn-sm"
                          type="button"
                          onClick={() => setOpenTemplates(true)}
                        >
                          <FormattedMessage id="outbound.modifyTemplate" />
                        </button>
                    )}
                  </div>
                </div>
                <div className="flex align-items-center gap-2">
                  <p
                    className={`${globalOutboundStyles.eventDescription} mb-0 fs-3 fw-semibold-0`}
                  >
                    <FormattedMessage
                      id={`outbound.${outboundEventLogsData?.outboundEvent.event}`}
                    />
                  </p>
                  <div className="flex gap-1 align-items-center">
                    <IconClockHour3
                      className={globalOutboundStyles.timeIcon}
                      size={16}
                    />
                    <p
                      className={`${globalOutboundStyles.eventDescription} mb-0 fs-2 fw-semibold-0`}
                    >
                      <FormattedMessage
                        id={`outbound.time_${outboundEventLogsData?.outboundEvent.event}`}
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex gap-1 align-items-center justify-content-between mb-3">
                <div
                  className={`${datePickerStyles.filtersContainer} ${styles.filters}`}
                >
                  <IonSelect
                    labelPlacement="stacked"
                    className={`${datePickerStyles.commonFilterSelect} ${styles.commonFilter}`}
                    value={commonFilter}
                    onIonChange={(e) => onChangeCommonFilter(e.target.value)}
                  >
                    {COMMON_FILTERS.map((filter) => (
                      <IonSelectOption
                        key={filter.value}
                        value={filter.value}
                        disabled={filter.value === "custom"}
                        className={filter.value === "custom" ? "d-none" : ""}
                      >
                        <FormattedMessage id={filter.label} />
                      </IonSelectOption>
                    ))}
                  </IonSelect>

                  <div className={datePickerStyles.dateWrapper}>
                    <IconCalendar size={17} />
                    <DatePicker
                      selectsRange
                      locale={locale}
                      className={datePickerStyles.dateInput}
                      wrapperClassName="w-100"
                      startDate={range.start}
                      endDate={range.end}
                      onChange={onChangeDates}
                      monthsShown={2}
                    />
                  </div>
                </div>
                {outboundEventLogsData &&
                  outboundEventLogsData?.pagination?.total > 0 && (
                    <p className={styles.totalsLabel}>
                      <FormattedMessage
                        id="common.results"
                        values={{
                          total: outboundEventLogsData.pagination.total,
                        }}
                      />
                    </p>
                  )}
              </div>

              <div className={tableStyles.tableContainer}>
                <table className="table mb-0">
                  <thead className={tableStyles.theadFixed}>
                    <tr className={tableStyles.tableHeader}>
                      <th className={tableStyles.tableTitle}>
                        <FormattedMessage id="common.date" />
                      </th>
                      <th className={tableStyles.tableTitle}>
                        <FormattedMessage id="common.name" />
                      </th>
                      <th className={tableStyles.tableTitle} colSpan={2}>
                        <FormattedMessage id="common.message" />
                      </th>
                    </tr>
                  </thead>
                  <tbody className={tableStyles.tableBody}>
                    {outboundEventLogsData?.logs.length === 0 && (
                      <tr className="fs-3">
                        <td className="text-center" colSpan={3}>
                          <FormattedMessage id="common.noResults" />
                        </td>
                      </tr>
                    )}
                    {outboundEventLogsData?.logs.map((o) => (
                      <tr key={o.id}>
                        <td className={tableStyles.commonTd}>{o.timestamp}</td>
                        <td
                          className={`${tableStyles.tdFullWidth} ${tableStyles.commonTd}`}
                        >
                          <Link
                            className="flex flex-column gap-1 no-underline fw-normal"
                            to={`/chats${
                              o.booking.contactId
                                ? `?chat=${o.booking.contactId}`
                                : ""
                            }`}
                          >
                            {o.booking.fullName || o.contact.name}
                            {o?.booking?.phoneNumber && (
                              <div className="flex align-items-center gap-2">
                                <div
                                  className="user-platform whatsapp-logo"
                                  style={{ height: "16px", width: "16px" }}
                                />
                                <p className="fs-1 mb-0">
                                  {o.booking.phoneNumber}
                                </p>
                              </div>
                            )}
                          </Link>
                        </td>
                        <td className={tableStyles.commonTd}>
                          {o.message ? (
                            <p className="mb-0"> {o.message.content} </p>
                          ) : null}
                        </td>
                        <td
                          className={`${tableStyles.commonTd} ${tableStyles.tdFullWidth}`}
                        >
                          <MessageStatus type={o.message?.status || "error"} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {outboundEventLogsData &&
                outboundEventLogsData?.pagination?.totalPages > 1 && (
                  <Pagination
                    currentPage={outboundEventLogsData.pagination.currentPage}
                    totalPages={outboundEventLogsData.pagination.totalPages}
                    onChangePage={(page) => setCurrentPage(page)}
                  />
                )}
            </>
          )}
        </div>
      </IonContent>

      {outboundEventLogsData?.outboundEvent
        && outboundEventLogsData.outboundEvent.templates
        && outboundEventLogsData?.outboundEvent.templates.length > 0 &&
        openTemplates && (
        <Suspense fallback="Loading...">
          <Templates
            onOpenChange={setOpenTemplates}
            event={outboundEventLogsData?.outboundEvent}
          />
        </Suspense>
      )}

      <IonLoading
        isOpen={isUpdatingEvent && openAlert}
        message={formatMessage({ id: "outbound.disabling" })}
        spinner="circles"
      />
      <IonAlert
        isOpen={openAlert}
        header={formatMessage({ id: "outbound.inactiveTitle" })}
        message={formatMessage({ id: "outbound.inactiveDesc" })}
        buttons={[
          {
            text: formatMessage({ id: "common.cancel" }),
            role: "cancel",
            handler: onHideAlert,
            cssClass: `${globalOutboundStyles.alertCancel} ${globalOutboundStyles.alertBtn}`,
          },
          {
            text: formatMessage({ id: "common.confirmInactive" }),
            role: "confirm",
            handler: onDisableEvent,
            cssClass: `${globalOutboundStyles.alertConfirm} ${globalOutboundStyles.alertBtn}`,
          },
        ]}
        onDidDismiss={onHideAlert}
      />
    </IonPage>
  );
};

export default OutboundLogs;
