import { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  useIonViewDidEnter,
  IonBackButton,
} from "@ionic/react";

//API
import AiApi from "../../../api/ai";

interface AiSetting {
  introduceAsBot: boolean;
}

const AiSettings: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [loading, setLoading] = useState(true);

  const [ai, setAi] = useState<AiSetting>({
    introduceAsBot: false,
  });

  useIonViewDidEnter(() => {
    requestAiSettings();
  });

  const requestAiSettings = async () => {
    console.log(loading);
    const { data } = await AiApi.getAiSettings();
    if (data) {
      setAi({ ...ai, introduceAsBot: data.aiSettings.introduceAsBot });
    }
    setLoading(false);
  };

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="ai-settings.title" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <div className="view-wrapper pt-4">
          <div className="view-pane-title list">
            {" "}
            <FormattedMessage id="ai-settings.paneTitle" />
          </div>
          <IonList inset={true}>
            <IonItem routerLink={`/ai-settings/general`} detail={true}>
              <IonLabel>
                <FormattedMessage id="ai-settings.businessInfo" />
              </IonLabel>
            </IonItem>
          </IonList>
          <div className="view-pane">
            <div className="view-pane-notes">
              <FormattedMessage id="ai-settings.businessInfoDescription" />
            </div>
          </div>
          <IonList inset={true}>
            <IonItem routerLink={`/ai-settings/customer`} detail={true}>
              <IonLabel>
                <FormattedMessage id="ai-settings.guestInfo" />
              </IonLabel>
            </IonItem>
          </IonList>
          <div className="view-pane">
            <div className="view-pane-notes">
              <FormattedMessage id="ai-settings.guestInfoDescription" />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {}

export default AiSettings;
