import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { IonPage, IonContent, IonText } from "@ionic/react";
import { deserialize } from "../../utils/serialize";
import Auth from "../../api/auth";
import { setAuth } from "../../utils/localStorage";
import { setAppUser, setAppAuth } from "../../actions/app";

import styles from "./styles.module.css";
import { RouteComponentProps } from "react-router-dom";

const UserInviteRegister: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { language } = props;
  const [show, setShow] = useState(false);
  interface UserInviteForm {
    password: string;
    token: string;
  }
  const [formData, setFormData] = useState<UserInviteForm>({
    password: "",
    token: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { search } = props.location;
    const { token } = deserialize(search);
    setFormData((prevFormData) => ({
      ...prevFormData,
      token,
    }));
  }, [props.location]);

  const register = async () => {
    setLoading(true);
    const { data, err } = await Auth.registerUser(formData);
    if (data) {
      const { token, refreshToken, user } = data;
      const { email, company, language } = user;
      await setAuth({ refreshToken, token, email, company });
      await props.dispatch(setAppAuth({ refreshToken, token }));
      await props.dispatch(setAppUser({ email, company, language }));
    }
    if (err) {
      const { error } = err;
      if (error && error[language]) {
        setError(error[language]);
      } else {
        setError(err.message);
      }
    }
    setLoading(false);
  };

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await register();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className={styles.authContainer}>
          <div className={styles.authHolder}>
            <div className={styles.authPanel}>
              <h1>
                <FormattedMessage id="auth.registerUser.title" />
              </h1>
              <h2>
                <FormattedMessage id="auth.registerUser.subtitle" />
              </h2>
              <div className="row">
                <div className="col-md-12">
                  <form className={styles.authForm} onSubmit={handleForm}>
                    <div
                      className={`${styles.inputHolder} ${styles.passwordHolder}`}
                    >
                      <label>
                        <FormattedMessage id="auth.register.password" />
                      </label>
                      <input
                        placeholder="Password"
                        type={show ? "text" : "password"}
                        className="form-control"
                        id="password"
                        value={formData.password}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                      <span
                        onClick={() => setShow(!show)}
                        className={styles.togglePassword}
                      ></span>
                    </div>

                    {error && (
                      <p>
                        <IonText className="mb-1" color="danger">
                          {error}
                        </IonText>
                      </p>
                    )}

                    <button
                      type="submit"
                      className="btn btn-visito-primary w-100 py-8 mt-4 mb-4 rounded-2"
                    >
                      {loading ? (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">
                            <FormattedMessage id="common.loading" />
                            ...
                          </span>
                        </Spinner>
                      ) : (
                        <FormattedMessage id="auth.register.register" />
                      )}
                    </button>

                    <div className="text-center mt-4">
                      <div className="color-step-500">
                        <FormattedMessage id="auth.register.loginLabel" />
                      </div>
                      <div>
                        <Link to={"/auth/login"}>
                          <FormattedMessage id="auth.register.login" />
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps extends RouteComponentProps {
  language: string;
  dispatch: Function;
}

export default connect((props: any) => ({}))(UserInviteRegister);
