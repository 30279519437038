import { createReducer } from "@reduxjs/toolkit";
import {
  setAppUser,
  setAppAuth,
  setAppLoading,
  cleanAppAuth,
  cleanAppUser,
  setSettings,
  setLanguage,
  setOnboarded,
} from "../actions/app";

import { OnboardingItem } from "../interfaces";
const email = localStorage.getItem("email");
const token = localStorage.getItem("token");
const refreshToken = localStorage.getItem("refreshToken");
const companyId = localStorage.getItem("company");

const auth = {
  token: token ? token : "",
  refreshToken: refreshToken ? refreshToken : "",
};

const user = {
  email: email ? email : "",
  company: {
    id: companyId ? companyId : "",
    slug: "",
  },
  logged: false,
  language: getLang(),
};

function getLang() {
  try {
    const navLang = navigator.language;
    if (navLang) {
      return navLang.split("-")[0];
    }
  } catch {
    return "en";
  }
}

const onboardingList: OnboardingItem[] = [];

const settings = {
  loading: true,
  badge: 0,
  onboardingList,
  canSkip: false,
  onboarded: false,
  events: false,
  status: {
    trial: false,
    payment: {
      active: true,
      email: "",
    },
    plan: {
      name: "",
    },
  },
};

const loading = true;

export const App = createReducer(
  { user, auth, loading, settings },
  {
    [setAppUser.type]: (state, action) => ({
      ...state,
      user: {
        ...action.payload,
        language: action.payload.language || getLang(),
        logged: true,
      },
    }),
    [setAppAuth.type]: (state, action) => ({
      ...state,
      auth: { ...action.payload },
    }),
    [setAppLoading.type]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    [cleanAppAuth.type]: (state, action) => ({
      ...state,
      auth: action.payload,
    }),
    [cleanAppUser.type]: (state, action) => {
      return {
        ...state,
        user: {
          ...user,
        },
      };
    },
    [setLanguage.type]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        language: action.payload,
      },
    }),
    [setSettings.type]: (state, action) => {
      const onboardingList = [
        { name: "account", done: true },
        { name: "fm", done: action.payload.fm || false },
        { name: "ig", done: action.payload.ig || false },
        { name: "ai", done: action.payload.ai || false },
        { name: "wa", done: action.payload.wa || false },
      ];
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          onboarded: action.payload.onboarded,
          badge: onboardingList.reduce((a, s) => a + (s.done ? 0 : 1), 0),
          canSkip: onboardingList
            .filter((o) => ["wa", "ig", "fm"].includes(o.name))
            .some((o) => o.done),
          onboardingList,
          events: action.payload.events,
          status: {
            ...state.settings.status,
            ...action.payload.status,
          },
        },
      };
    },
    [setOnboarded.type]: (state, action) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          onboarded: action.payload,
        },
      };
    },
  }
);
