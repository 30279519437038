export function removeAuth() {
  return new Promise((resolve) => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("tokenSetAt");
    localStorage.removeItem("company");
    return resolve();
  });
}

export function setAuth({ refreshToken, token, email, company }) {
  return new Promise((resolve) => {
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("token", token);
    localStorage.setItem("email", email);
    localStorage.setItem("company", company);
    localStorage.setItem("tokenSetAt", new Date());
    return resolve({
      refreshToken,
      token,
      email,
      company,
    });
  });
}

export function setCompany({ company }) {
  return new Promise((resolve) => {
    localStorage.setItem("company", company);
    return resolve({
      company,
    });
  });
}

export function getAuth() {
  return new Promise((resolve) => {
    const auth = {
      refreshToken: localStorage.getItem("refreshToken"),
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      tokenSetAt: localStorage.getItem("tokenSetAt"),
      company: localStorage.getItem("company"),
    };
    return resolve(auth);
  });
}
