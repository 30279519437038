import { connect } from "react-redux";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";

import { FormattedMessage } from "react-intl";

//Inferfaces
import { User } from "../../../interfaces";

import {
  IconPlug,
  IconLayout,
  IconSettings,
  IconUser,
  IconLogout,
  IconLanguage,
  IconCreditCard,
} from "@tabler/icons-react";

import config from "../../../config";

import headerStyles from "../../../theme/header.module.css";

const Header = () => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="settings.pageTitle" />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="settings.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const Settings: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { logOut, user } = props;
  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <FormattedMessage id="settings.pageTitle" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <div className="view-wrapper">
          <div className="view-pane">
            <Header />
          </div>

          <IonList inset={true}>
            <IonItem button routerLink={`/ai-settings`} detail={true}>
              <div slot="start">
                <IconSettings />
              </div>
              <IonLabel>
                <FormattedMessage id="settings.ai" />
              </IonLabel>
            </IonItem>
            <IonItem button routerLink={`/settings/integrations`} detail={true}>
              <div slot="start">
                <IconPlug />
              </div>
              <IonLabel>
                <FormattedMessage id="settings.integrations" />
              </IonLabel>
            </IonItem>
            <IonItem button routerLink={`/settings/channels`} detail={true}>
              <div slot="start">
                <IconLayout />
              </div>
              <IonLabel>
                <FormattedMessage id="settings.channels" />
              </IonLabel>
            </IonItem>
          </IonList>
          <IonList inset={true}>
            <IonItem button routerLink={`/settings/language`} detail={true}>
              <div slot="start">
                <IconLanguage />
              </div>
              <IonLabel>
                <FormattedMessage id="settings.language" />
              </IonLabel>
            </IonItem>
            <IonItem button routerLink={`/settings/payment`} detail={true}>
              <div slot="start">
                <IconCreditCard />
              </div>
              <IonLabel>
                <FormattedMessage id="settings.payment" />
              </IonLabel>
            </IonItem>
            <IonItem>
              <div slot="start">
                <IconUser />
              </div>
              <IonLabel>{user.email}</IonLabel>
            </IonItem>
            <IonItem button onClick={() => logOut()} detail={true}>
              <div slot="start">
                <IconLogout />
              </div>
              <IonLabel>
                <FormattedMessage id="nav.logout" />
              </IonLabel>
            </IonItem>
          </IonList>

          <div className="view-pane">{config.VERSION.vIdentifier}</div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  logOut: Function;
  user: User;
}

export default connect((props: any) => ({}))(Settings);
