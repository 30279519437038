import React, { useState } from "react";
import { IonPage, IonContent, IonText } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import "./login.css";
import Auth from "../../api/auth";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import styles from "./styles.module.css";
interface ForgotForm {
  email: string;
}

const ForgotPassword: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [loading, setLoading] = useState(false);
  const [forgotError, setForgotError] = useState("");
  const [forgotSuccess, setForgotSuccess] = useState("");
  const [formData, setFormData] = useState<ForgotForm>({ email: "" });
  const intl = useIntl();

  const forgotApi = async () => {
    setLoading(true);
    setForgotSuccess("");
    setForgotError("");
    const { data, err } = await Auth.forgotPassword(formData);
    if (data) {
      console.log(data);
      setForgotSuccess(
        intl.formatMessage({ id: "auth.forgotPassword.success" })
      );
    }
    if (err) {
      console.error(err);
      setForgotError(intl.formatMessage({ id: "auth.forgotPassword.error" }));
    }
    setLoading(false);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    forgotApi();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className={styles.authContainer}>
          <div className={styles.authHolder}>
            <div className={styles.authPanel}>
              <h1>
                <FormattedMessage id="auth.forgotPassword.title" />
              </h1>
              <h2>
                <FormattedMessage id="auth.forgotPassword.description" />
              </h2>
              <form className={styles.authForm} onSubmit={handleSubmit}>
                <div className={styles.inputHolder}>
                  <label>
                    <FormattedMessage id="auth.forgotPassword.email" />
                  </label>
                  <input
                    type="email"
                    id="email"
                    required
                    placeholder="name@example.com"
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                  />
                </div>
                {forgotError && (
                  <p>
                    <IonText className="mb-1 color-danger">
                      {forgotError}
                    </IonText>
                  </p>
                )}
                {forgotSuccess && (
                  <p>
                    <IonText className="mb-1 color-visito-3">
                      {forgotSuccess}
                    </IonText>
                  </p>
                )}
                <button
                  type="submit"
                  className="btn btn-visito-primary w-100 py-8 mb-4 mt-4 rounded-2"
                >
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">
                        <FormattedMessage id="auth.general.loading" />
                        ...
                      </span>
                    </Spinner>
                  ) : (
                    <FormattedMessage id="auth.forgotPassword.submit" />
                  )}
                </button>
                <div className="text-center mt-4">
                  <div className="color-step-500">
                    <FormattedMessage id="auth.login.registerLabel" />
                  </div>
                  <div>
                    <Link to="/auth/register">
                      <FormattedMessage id="auth.login.register" />
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({}))(ForgotPassword);
