import { IonAlert, IonContent, IonLoading, IonPage } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";

import SkeletonLoader from "../../../SkeletonLoader";

import LocationsSelect from "../../../common/locations-select";
import EmptyState from "../../empty";
import Last30Activity from "../../../Last30Activity";

import { OutboundEvent, OutboundEventStats, Location } from "../../../../interfaces";

import headerStyles from "../../../../theme/header.module.css";
import styles from "../../styles.module.css";
import List from "../../list";

const Header = () => (
  <div>
    <h1 className={headerStyles.pageTitle}>
      <FormattedMessage id="outbound.pageTitle" />
    </h1>

    <h3 className={headerStyles.pageDescription}>
      <FormattedMessage id="outbound.pageDescription" />
    </h3>
  </div>
);

type DesktopEventsProps = {
  loading: boolean;
  isUpdatingEvent: boolean;
  openAlert: boolean;
  total: number;
  outbound?: {
    events: OutboundEvent[];
    locations: Location[];
  },
  stats?: OutboundEventStats[];
  currentLocation: string;
  onChangeLocation: (location: string) => void;
  onDisplayAlert: (active: boolean, id: string) => void;
  onHideAlert: () => void;
  onDisableEvent: () => void;
}

const DesktopEvents = ({
  loading,
  total,
  outbound,
  stats,
  currentLocation,
  onChangeLocation,
  openAlert,
  isUpdatingEvent,
  onDisplayAlert,
  onHideAlert,
  onDisableEvent
}: DesktopEventsProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <IonPage id="main-content">
        <IonContent>
          <div className="page-wrapper container">
            <Header />
            {loading ? (
              <SkeletonLoader rows={6} />
            ) : (
              <>
                {outbound?.locations && outbound.locations.length > 1 && (
                  <LocationsSelect
                    value={currentLocation}
                    onChange={onChangeLocation}
                    options={outbound.locations}
                  />
                )}
                {outbound?.events?.length ? (
                  <>
                    <div className="row mb-3">
                      <div className="col">
                        <div className="visito-card visito-block">
                          <div className="visito-card-body">
                            <p className="mb-0 pb-1 fs-2 fw-semibold-0">
                              <FormattedMessage id="outboud.messagesSent" />
                            </p>
                            <p className="mb-2 fs-6 fw-bold">
                              {Intl.NumberFormat("en-US").format(total)}
                            </p>
                            <Last30Activity
                              stats={stats || []}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <div className={`${styles.divider}`} />
                      </div>
                    </div>
                    <div className="pb-4">
                      <div className="row gy-4">
                        <List
                          events={outbound?.events}
                          onDisplayAlert={onDisplayAlert}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <EmptyState isMobile={false} />
                )}
              </>
            )}
          </div>
        </IonContent>
      </IonPage>
      <IonLoading
        isOpen={isUpdatingEvent && openAlert}
        message={formatMessage({ id: "outbound.disabling" })}
        spinner="circles"
      />
      <IonAlert
        isOpen={openAlert}
        header={formatMessage({ id: "outbound.inactiveTitle" })}
        message={formatMessage({ id: "outbound.inactiveDesc" })}
        buttons={[
          {
            text: formatMessage({ id: "common.cancel" }),
            role: "cancel",
            handler: onHideAlert,
            cssClass: styles.alertCancel,
          },
          {
            text: formatMessage({ id: "common.confirmInactive" }),
            role: "confirm",
            handler: onDisableEvent,
            cssClass: styles.alertConfirm,
          },
        ]}
        onDidDismiss={onHideAlert}
      />
    </>
  );
}

export default DesktopEvents;
